import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Button, Grid } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import {  useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUnidadeNegocioGuard } from "../../../../../common/hooks/useUnidadeNegocioGuard";
import { FollowUpDto } from "../../../../../common/types/followUp";
import { selectUnidadeSelecionada } from "../../../../../../features/usuario-logado/usuarioLogadoSlice";
import { KEYCLOAK_TOKEN_TIMEOUT } from "../../../../../../App";
import { setErrorFeedback, setSuccessFeedback } from "../../../../../../features/feedback/feedbackSlice";
import { downloadFile } from "../../sapMtn.helpers";
import { fetchByFilterDto } from "../../../../../../features/follow-up/followUpAPI";
import PageLayout from "../../../../../common/layouts/pageLayout";
import LoadingIndicator from "../../../../../common/components/loadingIndicator";
import FormHeaderMedium from "../../../../../common/components/forms/formHeaderMedium";
import SelectStyled from "../../../../../common/components/input/selectStyled";
import ErrorBadge from "../../../../../common/components/forms/errorBadge";

const SapMtnDiPage = () => {
  useUnidadeNegocioGuard();
  const dispatch = useDispatch();
  const [error, setError] = useState<string | string[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [followUpsData, setFollowUpsData] = useState<FollowUpDto[] | undefined>(undefined);
  const [processo, setProcesso] = useState<string | undefined>(undefined);
  const { keycloak } = useKeycloak();
  const selectedUnidade: number = useSelector(selectUnidadeSelecionada);
  const { token } = keycloak;

  const url = process.env.REACT_APP_QCX_API_BASE_URL! + "/"
    + process.env.REACT_APP_QCX_API_DECLARACAO_IMPORTACAO_ENDPOINT!
    + "/sap-mtn/xml/escrita"

  const processos = useMemo(() => {
    if (!followUpsData || typeof followUpsData != "object") {
      return [];
    }

    const processos: string[] = followUpsData.map(
      (followUp: FollowUpDto) => followUp.numero
    );

    return processos.filter((processo, index) => {
      return processos.indexOf(processo) === index;
    });
  }, [followUpsData]);

  const clearData = () => {
    setProcesso(undefined);
    setIsLoading(false);
  };

  const handleSubmit = async () => {
    if (processo && token && selectedUnidade) {
      keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
      setIsLoading(true)

      const message = await downloadFile(token, selectedUnidade.toString(), `${url}?processo=${processo}`, "di-sap-mtn-" + processo);

      if (message.statusCode === 500) {
        dispatch(setErrorFeedback({
          message: message.message
        }));

      } else {
        dispatch(setSuccessFeedback({
          message: message.message
        }));

      }
      clearData();
    }
  };

  const getFollowUps = async () => {
    setIsLoading(true);
    try {

      const { data } = await fetchByFilterDto([
        { name: 'filterDadosMinimos', value: 'TRUE' },
        { name: 'produto', value: 'IMPORTACAO' },
      ]);

      setFollowUpsData(data);

    } catch (error) {

      dispatch(setErrorFeedback({
        message: 'Erro ao obter FollowUps:'
      }));

    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    getFollowUps();
  }, []);

  return (
    <PageLayout
      title={"Sap-Mtn Di"}
      icon={<InsertDriveFileIcon color={"secondary"} />}
    >
      {isLoading ? (
        <LoadingIndicator
          message={isLoading ? "Gerando Xml" : "Carregando Dados..."}
        />
      ) : (
        <Grid container spacing={2}>
          <FormHeaderMedium>
            Selecione as informações para gerar o arquivo
          </FormHeaderMedium>
          <Grid item sm={6}>
            <SelectStyled
              options={processos.map((processo, index) => ({
                id: index,
                value: processo,
              }))}
              label={"Processo"}
              onChangeAction={(event, newValue) => {
                setProcesso(newValue?.value);
              }}
            />
          </Grid>
          <ErrorBadge error={error} />
          <Grid item sm={12} textAlign={"right"}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              disabled={!processo || isLoading || !!error}
            >
              Gerar
            </Button>
          </Grid>
        </Grid>
      )}
    </PageLayout>
  );
};

export default SapMtnDiPage;