import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { Button, Dialog, FormControl, Grid, Typography } from '@mui/material';
import QCXFinalDatePickerField from '../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import { useEffect, useState, SyntheticEvent } from 'react';
import {
  selectUnidadesDeNegocioAssociadas,
  selectUnidadeSelecionada,
} from '../../../../features/usuario-logado/usuarioLogadoSlice';
import { KEYCLOAK_TOKEN_TIMEOUT } from '../../../../App';
import { useListClientes } from '../../../common/hooks/useListClientes';
import { setErrorFeedback, setSuccessFeedback } from '../../../../features/feedback/feedbackSlice';
import { generateDespesasPagasReport } from './despesasPagasPorFornecedorPage.helpers';
import MultiSelectStyled, { SimpleMultiSelectOption } from '../../../common/components/input/multiSelectStyled';
import { Form, Field } from 'react-final-form';
import { Box, DialogActions, DialogContent, DialogTitle, makeStyles, MenuItem } from '@material-ui/core';
import { GridItemModalProps } from '../../../common/components/cardsGrid/cardsModalGrid';
import QCXTextField from '../../../../shared-components/text-field/QCXTextField';
import QCXSelectContaBancariaAutocompleteField from '../../../../shared-components/select-conta-bancaria/QCXSelectContaBancariaAutocompleteField';
import QCXSelectFornecedorAutocomplete from '../../../../shared-components/select-fornecedor-favorecido-autocomplete/QCXSelectFornecedorAutocomplete';
// Import the despesa autocomplete component
import QCXSelectDespesaReceitaAutocomplete from '../../../../shared-components/select-despesa-receita/QCXSelectDespesaReceitaAutocomplete';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      minWidth: '50vw',
    },
  },
});

const DespesasPagasPorFornecedorPage = ({ title, isOpen, closeModal }: GridItemModalProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const { token } = keycloak;
  keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);

  const tenantList = useSelector(selectUnidadesDeNegocioAssociadas);
  const selectedUnit = useSelector(selectUnidadeSelecionada);
  const [selectedTenants, setSelectedTenants] = useState<SimpleMultiSelectOption[]>([]);
  const [selectedFornecedor, setSelectedFornecedor] = useState<string>('');
  const [clientes] = useListClientes();
  const [selectedClients, setSelectedClients] = useState<SimpleMultiSelectOption[]>([]);
  const [selectedDespesaReceitaId, setSelectedDespesaReceitaId] = useState<string>(''); // Add despesa state
  const [startDateISO, setStartDateISO] = useState('');
  const [endDateISO, setEndDateISO] = useState('');
  const [origem, setOrigem] = useState<string>('');
  const [bancoId, setBancoId] = useState<number | null>(null);

  const handleTenantsChange = (event: SyntheticEvent<Element, Event>, value: SimpleMultiSelectOption[] | null) => {
    setSelectedTenants(value || []);
  };

  const handleClientsChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: SimpleMultiSelectOption[] | null
  ) => {
    setSelectedClients(value || []);
  };

  const handleOrigemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrigem(event.target.value);
  };

  const handleChangeBanco = (_event: any, option: any) => {
    setBancoId(option?.id || null);
  };

  // Handler for despesa change
  const handleDespesaReceitaChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setSelectedDespesaReceitaId('');
    if (newValue) {
      setSelectedDespesaReceitaId(newValue?.id);
    }
  };

  const handleSubmit = async () => {
    keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);

    if (selectedTenants.length === 0) {
      dispatch(setErrorFeedback({ message: 'Selecione pelo menos uma Unidade de Negócio.' }));
      return;
    }

    if (!startDateISO || !endDateISO) {
      dispatch(setErrorFeedback({ message: 'Selecione as datas de início e fim.' }));
      return;
    }

    if (token && selectedUnit) {
      try {
        const tenantIds = selectedTenants.map((tenant) => tenant.id.toString());
        const clientIds = selectedClients.map((client) => client.id.toString());

        const error = await generateDespesasPagasReport(
          token,
          selectedUnit.toString(),
          tenantIds,
          clientIds,
          selectedFornecedor,
          selectedDespesaReceitaId, // Pass the selected despesa ID
          startDateISO,
          endDateISO,
          origem,
          bancoId
        );

        if (error) {
          dispatch(setErrorFeedback({ message: error }));
        } else {
          dispatch(setSuccessFeedback({ message: 'Relatório gerado com sucesso!' }));
        }
      } catch (error) {
        dispatch(setErrorFeedback({ message: 'Erro inesperado ao gerar o relatório.' }));
      }
    }
  };

  return (
    <Dialog className={classes.root} open={isOpen} onClose={closeModal}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Form onSubmit={() => {}}>
          {() => (
            <form>
              <Grid container spacing={2}>
                {/* Unidade de Negócio */}
                <Grid item xs={12} style={{ marginBottom: '25px' }}>
                  <FormControl fullWidth error={selectedTenants.length === 0}>
                    <MultiSelectStyled
                      disablePortal={false}
                      controlledValues={selectedTenants}
                      options={tenantList.map((tenant: { id: any; pessoa: { nomeResumido: any } }) => ({
                        id: tenant.id,
                        value: tenant.pessoa.nomeResumido,
                      }))}
                      onChangeAction={handleTenantsChange}
                      label={'Unidade de Negócio'}
                    />
                    {selectedTenants.length === 0 && (
                      <Typography variant="body2" color="error">
                        Este campo é obrigatório
                      </Typography>
                    )}
                  </FormControl>
                </Grid>

                {/* Clientes Multi-Select */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <MultiSelectStyled
                      disablePortal={false}
                      options={clientes.map((client) => ({
                        id: client.id ?? '',
                        value: `(${client.pessoa!.cnpj}) ${client.pessoa!.nome}`,
                      }))}
                      onChangeAction={handleClientsChange}
                      label={'Clientes'}
                    />
                  </FormControl>
                </Grid>

                {/* Despesa Select */}
                <Grid item xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <QCXSelectDespesaReceitaAutocomplete
                      id="select-field-despesa"
                      key="select-field-despesa"
                      name="despesaReceita.id"
                      label="Descrição"
                      initialValues={selectedDespesaReceitaId}
                      disabled={false}
                      // You can add filterParams or filterPredicate if needed
                      // filterParams={configureDespesaReceitaFilterPredicateWith}
                      onChange={handleDespesaReceitaChange}
                      filterParams={undefined}
                    />
                  </FormControl>
                </Grid>

                {/* Fornecedor Select */}
                <Grid item xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <QCXSelectFornecedorAutocomplete
                      id="select-field-fornecedor"
                      key="select-field-fornecedor"
                      name="fornecedorId"
                      label="Fornecedor"
                      initialValues={selectedFornecedor}
                      disabled={false}
                      filterPredicate={() => false}
                      onChange={(event: React.ChangeEvent<{}>, newValue: any) => {
                        setSelectedFornecedor('');
                        if (newValue) {
                          setSelectedFornecedor(newValue?.id);
                        }
                      }}
                    />
                  </FormControl>
                </Grid>

                {/* Origem */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <QCXTextField
                      select
                      label="Origem"
                      value={origem}
                      onChange={handleOrigemChange}
                      fullWidth
                      maxLength={255}
                      visible={true}
                      helperText=""
                      meta={{}}
                      disabled={false}
                    >
                      <MenuItem value="">Todos</MenuItem>
                      <MenuItem value="OPERACIONAL">OPERACIONAL</MenuItem>
                      <MenuItem value="ADMINISTRATIVA">ADMINISTRATIVA</MenuItem>
                    </QCXTextField>
                  </FormControl>
                </Grid>

                {/* Conta Bancária */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <QCXSelectContaBancariaAutocompleteField
                      id="autocomplete-select-conta-bancaria-field"
                      key="autocomplete-select-conta-bancaria-field"
                      name="contaBancaria"
                      label="Conta Bancária"
                      initialValues={undefined}
                      filtraControlaSaldo={true}
                      onChange={handleChangeBanco}
                    />
                  </FormControl>
                </Grid>

                {/* Date fields */}
                <Grid item xs={6} style={{ marginBottom: '25px' }}>
                  <Field
                    name="startDate"
                    render={({ input }) => (
                      <QCXFinalDatePickerField
                        id="date-picker-start-date"
                        name={input.name}
                        label={'Data de Início'}
                        required={true}
                        format="DD/MM/YYYY"
                        placeholder="DD/MM/YYYY"
                        onChange={(date: any) => {
                          const earlierDate = new Date(date);
                          earlierDate.setDate(earlierDate.getDate() - 1);
                          setStartDateISO(earlierDate.toISOString());
                          input.onChange(date);
                        }}
                        validate={undefined}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} style={{ marginBottom: '25px' }}>
                  <Field
                    name="endDate"
                    render={({ input }) => (
                      <QCXFinalDatePickerField
                        id="date-picker-end-date"
                        name={input.name}
                        label={'Data de Fim'}
                        required={true}
                        format="DD/MM/YYYY"
                        placeholder="DD/MM/YYYY"
                        onChange={(date: any) => {
                          setEndDateISO(date.toISOString());
                          input.onChange(date);
                        }}
                        validate={undefined}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
      </DialogContent>
      <DialogActions>
        <Box padding={2} display="flex" gridGap={4}>
          <Button variant="outlined" color="primary" onClick={closeModal}>
            Fechar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            fullWidth
            disabled={selectedTenants.length === 0 || !startDateISO || !endDateISO}
          >
            Gerar relatório
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DespesasPagasPorFornecedorPage;
