import { validateQueryParams } from '../../utils/api/api-utils';
import {
  doGetQCXRequest,
  doPostQCXRequest,
  doPutQCXActivateRequest,
  doPutQCXInactivateRequest,
  doPutQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import { CATALOGO_PRODUTOS_ENDPOINT, FILTER_ENDPOINT } from '../../utils/api/domain/domain-endpoint-utils';

export async function fetchCatalogoByFilter(params = []) {
  const validatedParams = validateQueryParams(params);
  if (!validatedParams.length) {
    throw new Error('Invalid params');
  }

  return doGetQCXRequest([CATALOGO_PRODUTOS_ENDPOINT, FILTER_ENDPOINT, 'pagination'], validatedParams);
}

export async function fetchAll() {
  return doGetQCXRequest([CATALOGO_PRODUTOS_ENDPOINT]);
}

export async function fetchById(id) {
  return doGetQCXRequest([CATALOGO_PRODUTOS_ENDPOINT, id]);
}
export async function fetchByFilter(params = []) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest([CATALOGO_PRODUTOS_ENDPOINT, FILTER_ENDPOINT], validatedParams);
  }
  return { data: [] };
}

export async function fetchByProdutosPortalUnico(params = []) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest([CATALOGO_PRODUTOS_ENDPOINT, 'obter-produto'], validatedParams);
  }
  return { data: [] };
}

export async function register(data) {
  return doPostQCXRequest([CATALOGO_PRODUTOS_ENDPOINT], data);
}

export async function batchRegister(data) {
  return doPostQCXRequest([CATALOGO_PRODUTOS_ENDPOINT, 'all'], data);
}

export async function batchSave(data) {
  return doPutQCXRequest([CATALOGO_PRODUTOS_ENDPOINT, 'all'], data);
}

export async function save(data) {
  return doPutQCXRequest([CATALOGO_PRODUTOS_ENDPOINT], data);
}

export async function saveAtributos(data) {
  return doPutQCXRequest([CATALOGO_PRODUTOS_ENDPOINT, 'save-atributos'], data);
}

export async function activateById(id) {
  return doPutQCXActivateRequest([CATALOGO_PRODUTOS_ENDPOINT], id);
}

export async function inactivateById(id) {
  return doPutQCXInactivateRequest([CATALOGO_PRODUTOS_ENDPOINT], id);
}

export async function batchTransmiteCatalogos(data) {
  return doPostQCXRequest([CATALOGO_PRODUTOS_ENDPOINT, 'send-catalogos'], {
    ids: data.ids,
    state: data.state,
    somenteVincularOperadorEstrangeiro: !data.transmitirProdutos,
  });
}

export async function generateExcelReport(params) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest([CATALOGO_PRODUTOS_ENDPOINT, 'generate-excel'], validatedParams, {
      responseType: 'arraybuffer',
    });
  }
  return { data: [] };
}

export async function fetchLogsByCatalogoProdutosId(id) {
  return doGetQCXRequest([CATALOGO_PRODUTOS_ENDPOINT, id, 'logs']);
}
