import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXSelectMoedaAutocomplete from '../../shared-components/final-select-moeda-field/QCXSelectMoedaAutocomplete';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXSelectContaContabilAutocomplete from '../../shared-components/select-conta-contabil/QCXSelectContaContabilAutocomplete';
import QCXSelectTipoCalculoAutocomplete from '../../shared-components/select-tipo-calculo/QCXSelectTipoCalculoAutocomplete';
import QCXFinalSelectTipoModeloAutocomplete from '../../shared-components/select-tipo-modelo/QCXFinalSelectTipoModeloAutocomplete';
import QCXFinalSelectTipoRateioDespesaReceitaAutocomplete from '../../shared-components/select-tipo-rateio-despesa-receita/QCXFinalSelectTipoRateioDespesaReceitaAutocomplete';
import { required as requiredValidator } from '../../utils/validators/field/validator';

export default function QCXDespesaReceitaForm({
  isCreate,
  isConsult,
  initialValues,
  handleSubmit,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  ...restProps
}) {
  const { t } = useTranslation();

  return (
    <>
      <QCXFinalForm
        isCreate={isCreate}
        isConsult={isConsult}
        initialValues={initialValues}
        handleSubmitValues={handleSubmit}
        handleChangeToUpdate={handleChangeToUpdate}
        handleCancelUpdate={handleCancelUpdate}
        {...restProps}
      >
        {({ values }) => (
          <>
            <Grid item xs={12} sm={4} md={4} lg={2}>
              <QCXFinalTextField
                id="text-field-code"
                key="text-field-code"
                name="id"
                label={t('com.muralis.qcx.codigo')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={12} sm={isCreate ? 12 : 8} md={5} lg={isCreate ? 6 : 8}>
              <QCXFinalTextField
                id="text-field-description"
                key="text-field-description"
                name="description"
                label={t('com.muralis.qcx.descricao')}
                disabled={isConsult}
                validate={requiredValidator}
                required
              />
            </Grid>

            <Grid item xs={12} sm={5} md={3} lg={2}>
              <QCXFinalSelectTipoModeloAutocomplete
                id="autocomplete-select-field-tipo"
                key="autocomplete-select-field-tipo"
                name="tipo"
                label={t('com.muralis.qcx.tipo')}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                initialValues={values}
                validate={requiredValidator}
                required
              />
            </Grid>

            <Grid item xs={12} sm={isCreate ? 12 : 8} md={5} lg={6}>
              <QCXFinalTextField
                id="text-field-description-english"
                key="text-field-description-english"
                name="descriptionEnglish"
                label={t('com.muralis.qcx.descricaoEnglish')}
                disabled={isConsult}                
              />
            </Grid>

            <Grid item xs={12} sm={isCreate ? 12 : 8} md={5} lg={6}>
              <QCXFinalTextField
                id="text-field-description-espanhol"
                key="text-field-description-espanhol"
                name="descriptionEspanhol"
                label={t('com.muralis.qcx.descricaoEspanhol')}
                disabled={isConsult}                
              />
            </Grid>

            <Grid item xs={12} sm={7} md={4} lg={isCreate ? 4 : 5}>
              <QCXSelectContaContabilAutocomplete
                id="autocomplete-select-field-plano-de-conta"
                key="autocomplete-select-field-plano-de-conta"
                name="planoDeConta.id"
                label={t('com.muralis.qcx.empresa.contaContabil')}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                initialValues={values}
              />
            </Grid>
            <Grid item xs={12} sm={5} md={4} lg={3}>
              <QCXFinalSelectTipoRateioDespesaReceitaAutocomplete
                id="autocomplete-select-field-tipo-rateio"
                key="autocomplete-select-field-tipo-rateio"
                name="tipoRateio"
                label={t('com.muralis.qcx.tipoRateio')}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                initialValues={values}
                required
              />
            </Grid>
            <Grid item xs={12} sm={7} md={4} lg={4}>
              <QCXSelectTipoCalculoAutocomplete
                id="autocomplete-select-field-tipo-calculo"
                key="autocomplete-select-field-tipo-calculo"
                name="tipoCalculo.id"
                label={t('com.muralis.qcx.tipoCalculo')}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                initialValues={values}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={5}>
              <QCXSelectMoedaAutocomplete
                id="autocomplete-select-field-moeda"
                key="autocomplete-select-field-moeda"
                name="moeda.id"
                label={t('com.muralis.qcx.moeda.label')}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                initialValues={values}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <QCXFinalCheckboxField
                id="checkbox-field-despesa-receita-operacional"
                key="checkbox-field-despesa-receita-operacional"
                name="operacional"
                label={t('com.muralis.qcx.operacional')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <QCXFinalCheckboxField
                id="checkbox-field-despesa-receita-aduaneira"
                key="checkbox-field-despesa-receita-aduaneira"
                name="aduaneira"
                label={t('com.muralis.qcx.aduaneira')}
                disabled={isConsult}
              />
            </Grid>
          </>
        )}
      </QCXFinalForm>
    </>
  );
}
