import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { useHistory } from 'react-router';
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Autocomplete,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, TextField } from '@material-ui/core';
import { setErrorFeedback, setSuccessFeedback } from '../../../../features/feedback/feedbackSlice';
import { generateSpreadsheetLivroContas } from './relatorioLivrocontasPage.helpers';
import { useUnidadeNegocioGuard } from '../../../common/hooks/useUnidadeNegocioGuard';
import { SelectChangeEvent } from '@mui/material';
import { fetchAll as fetchAllFornecedores } from '../../../../features/fornecedor-favorecido/FornecedorAPI';
import {
  selectUnidadeSelecionada,
  selectUnidadesDeNegocioAssociadas,
} from '../../../../features/usuario-logado/usuarioLogadoSlice';
import { GridItemModalProps } from '../../../common/components/cardsGrid/cardsModalGrid';
import { Field, Form } from 'react-final-form';
import QCXFinalDatePickerField from '../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXTextField from '../../../../shared-components/text-field/QCXTextField';
import QCXSelectContaBancariaAutocompleteField from '../../../../shared-components/select-conta-bancaria/QCXSelectContaBancariaAutocompleteField';
import theme from '../../../common/themes/theme';
import QCXSelectFornecedorAutocomplete from '../../../../shared-components/select-fornecedor-favorecido-autocomplete/QCXSelectFornecedorAutocomplete';


const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      minWidth: '50vw',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    gap: '10px',
  },
  input: {
    height: 40,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  button: {
    borderRadius: '4px',
  },
});

const RelatorioLivroContasPage = ({ title, isOpen, closeModal }: GridItemModalProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { keycloak } = useKeycloak();
  const { token } = keycloak;

  keycloak.updateToken(300);

  const selectedUnit = useSelector(selectUnidadeSelecionada);
  const businessUnits = useSelector(selectUnidadesDeNegocioAssociadas);
  const [fornecedores, setFornecedores] = useState([]);
  const [selectedFornecedores, setSelectedFornecedores] = useState('');


  const [datas, setDatas] = useState({
    dataInicio: '',
    dataFim: '',
  });
  useUnidadeNegocioGuard();

  useEffect(() => {
    const fetchFornecedores = async () => {
      try {
        const response = await fetchAllFornecedores();
        if (response.status === 200) {
          setFornecedores(response.data);
        } else {
          dispatch(
            setErrorFeedback({
              message: 'Erro ao buscar fornecedores.',
            })
          );
        }
      } catch (error) {
        dispatch(
          setErrorFeedback({
            message: 'Erro ao buscar fornecedores.',
          })
        );
      }
    };
  
    fetchFornecedores();
  }, [dispatch]);
  

  const [selectedBusinessUnits, setSelectedBusinessUnits] = useState<string[]>([]);
  const [origem, setOrigem] = useState('');
  const [bancoId, setBancoId] = useState<number | null>(null); // Added bancoId (conta)

  const validarDatas = (datas: { dataInicio: string; dataFim: string }): boolean => {
    if (!datas.dataInicio || !datas.dataFim) {
      return false;
    }
    const dataInicio = new Date(datas.dataInicio);
    const dataFim = new Date(datas.dataFim);
    return dataInicio <= dataFim;
  };

  const handleChangeDataInicio = (isoDate: string) => {
    const value = isoDate.split('T')[0];

    setDatas((prevDatas) => ({ ...prevDatas, dataInicio: value }));
  };

  const handleChangeDataFim = (isoDate: string) => {
    const value = isoDate.split('T')[0];

    setDatas((prevDatas) => ({ ...prevDatas, dataFim: value }));
  };

  const handleBusinessChange = (event: SelectChangeEvent<string | string[]>) => {
    const value = event.target.value as string[];
    setSelectedBusinessUnits(value);
  };

  const handleOrigemChange = (event: SelectChangeEvent<string>) => {
    setOrigem(event.target.value);
  };

  const handleChangeBanco = (_event: any, option: any) => {
    setBancoId(option?.id || null);
  };

  const handleSubmit = useCallback(async () => {
    keycloak.updateToken(300);
  
    if (!validarDatas(datas)) {
      dispatch(
        setErrorFeedback({
          message: 'Preencha as datas de início e fim corretamente.',
        })
      );
      return;
    }
  
    if (token && selectedUnit) {
      try {
  
        const error = await generateSpreadsheetLivroContas(
          datas.dataInicio,
          datas.dataFim,
          token,
          selectedUnit, // Tenant
          selectedBusinessUnits, // Business Units
          selectedFornecedores, // Fornecedor IDs
          origem, // Origem
          bancoId // Added bancoId (conta) parameter
        );
        if (error != null) {
          dispatch(
            setErrorFeedback({
              message: error ? error : 'Erro ao gerar a planilha.',
            })
          );
        } else {
          dispatch(
            setSuccessFeedback({
              message: 'Planilha gerada com sucesso!',
            })
          );
        }
      } catch (error) {
        dispatch(
          setErrorFeedback({
            message: 'Erro inesperado ao gerar o relatório.',
          })
        );
      }
    }
    keycloak.updateToken(300);
    history.push('/financeiro/relatorios');
  }, [
    datas,
    token,
    selectedUnit,
    selectedBusinessUnits,
    selectedFornecedores,
    origem,
    bancoId,
    dispatch,
    keycloak,
    history,
  ]);
  

  return (
    <Dialog className={classes.root} open={isOpen} onClose={closeModal}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Form onSubmit={() => {}}>
          {() => (
            <form>
              <Grid container spacing={2}>
                {/* Unidade de Negócio multi-select */}
                <Grid item xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel shrink sx={{ paddingLeft: '4px', paddingRight: '4px', marginLeft: '-4px' }}>
                      UNIDADE DE NEGÓCIO
                    </InputLabel>
                    <Select
                      multiple
                      value={selectedBusinessUnits}
                      onChange={handleBusinessChange}
                      input={
                        <OutlinedInput
                          className={
                            classes.input +
                            ' MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMarginDense MuiOutlinedInput-inputMarginDense'
                          }
                        />
                      }
                      renderValue={(selected) => (selected as string[]).join(', ')}
                    >
                      {businessUnits.map((business: { id: string; pessoa: { nomeResumido: string } }) => (
                        <MenuItem key={business.id} value={business.id}>
                          <Checkbox checked={selectedBusinessUnits.indexOf(business.id) > -1} />
                          <ListItemText primary={business.pessoa.nomeResumido} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Fornecedor multi-select */}
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                    <QCXSelectFornecedorAutocomplete
                      id="select-field-fornecedor"
                      key="select-field-fornecedor"
                      name="fornecedorIds"
                      label="Fornecedor"
                      initialValues={selectedFornecedores}
                      disabled={false}
                      filterPredicate={() => false} // Added filterPredicate
                      onChange={(event: React.ChangeEvent<{}>, newValue: any) => {
                      setSelectedFornecedores('');
                      if(newValue){
                        setSelectedFornecedores(newValue?.id);
                      }
                      }}
                    />
                    </FormControl>
                </Grid>

                {/* Origem select */}
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl fullWidth>
                    <QCXTextField
                      select
                      label="Origem"
                      value={origem}
                      onChange={handleOrigemChange}
                      fullWidth
                      maxLength={255}
                      visible={true}
                      helperText=""
                      meta={{}}
                      disabled={false}
                    >
                      <MenuItem value="">Todos</MenuItem>
                      <MenuItem value="OPERACIONAL">OPERACIONAL</MenuItem>
                      <MenuItem value="ADMINISTRATIVA">ADMINISTRATIVA</MenuItem>
                    </QCXTextField>
                  </FormControl>
                </Grid>

                {/* Conta Bancária */}
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl fullWidth>
                    <QCXSelectContaBancariaAutocompleteField
                      id="autocomplete-select-conta-bancaria-field"
                      key="autocomplete-select-conta-bancaria-field"
                      name="contaBancaria"
                      label="Conta Bancária"
                      initialValues={undefined}
                      filtraControlaSaldo={true}
                      onChange={handleChangeBanco}
                    />
                  </FormControl>
                </Grid>

                {/* Data de Início */}
                <Grid item xs={6} style={{ marginBottom: '25px' }}>
                  <Field
                    name="startDate"
                    render={({ input }) => (
                      <QCXFinalDatePickerField
                        id="livro-contas-start-date"
                        name={input.name}
                        label={'Data de Início'}
                        required={true}
                        format="DD/MM/YYYY"
                        placeholder="DD/MM/YYYY"
                        onChange={(date: any) => {
                          handleChangeDataInicio(date.toISOString());
                          input.onChange(date);
                        }}
                        validate={undefined}
                      />
                    )}
                  />
                </Grid>
                {/* Data Final */}
                <Grid item xs={6} style={{ marginBottom: '25px' }}>
                  <Field
                    name="endDate"
                    render={({ input }) => (
                      <QCXFinalDatePickerField
                        id="livro-contas-end-date"
                        name={input.name}
                        label={'Data Final'}
                        required={true}
                        format="DD/MM/YYYY"
                        placeholder="DD/MM/YYYY"
                        onChange={(date: any) => {
                          handleChangeDataFim(date.toISOString());
                          input.onChange(date);
                        }}
                        validate={undefined}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
      </DialogContent>
      <DialogActions>
        <Box padding={2} display="flex" gridGap={4}>
          <Button variant="outlined" color="primary" onClick={closeModal}>
            Fechar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            fullWidth
            disabled={
              datas.dataFim === '' ||
              datas.dataInicio === '' ||
              !selectedBusinessUnits.length
            }
          >
            Gerar Relatório
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default RelatorioLivroContasPage;
