import { Grid } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalFormDialog from '../../shared-components/dialog/QCXFinalFormDialog';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXSelectContaBancariaAutocompleteField from '../../shared-components/select-conta-bancaria/QCXSelectContaBancariaAutocompleteField';
import { CLOSED_STATUS, isOpenStatus } from '../../utils/hooks/form/dialog/formDialogUtils';

export default function QCXTransferenciaBancariaDialogForm({
  handleOnSubmit,
  handleFormStatus,
  formDialogStatus,
  initialValues,
}) {
  const { t } = useTranslation();

  const isFormDialogOpen = useMemo(() => isOpenStatus(formDialogStatus), [formDialogStatus]);

  const handleCloseFormDialogNew = useCallback(() => {
    handleFormStatus(CLOSED_STATUS);
  }, [handleFormStatus]);

  return (
    <QCXFinalFormDialog
      key="form-dialog-transferencia-bancaria"
      id="form-dialog-transferencia-bancaria"
      title="Transferência"
      dialogActionsProps={{
        button: {
          submit: {
            description: t('com.muralis.qcx.acoes.transferir'),
            size: 'small',
            color: 'secondary',
          },
          cancel: {
            description: t('com.muralis.qcx.acoes.cancelar'),
            size: 'small',
          },
        },
      }}
      open={isFormDialogOpen}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      onClose={handleCloseFormDialogNew}
      maxWidth="md"
      fullWidth
    >
      {({ values, form }) => (
        <>
          <Grid container sm={12} spacing={2}>
            <Grid item sm={12}>
              <QCXSelectContaBancariaAutocompleteField
                id="autocomplete-select-conta-bancaria-transferencia-field-origem"
                key="autocomplete-select-conta-bancaria-transferencia-field-origem"
                name="contaBancariaOrigem"
                label={t('com.muralis.qcx.contaBancaria.contaBancariaOrigem')}
                initialValues={values}
              />
            </Grid>
            <Grid item sm={12}>
              <QCXSelectContaBancariaAutocompleteField
                id="autocomplete-select-conta-bancaria-transferencia-field-destino"
                key="autocomplete-select-conta-bancaria-transferencia-field-destino"
                name="contaBancariaDestino"
                label={t('com.muralis.qcx.contaBancaria.contaBancariaDestino')}
                initialValues={values}
              />
            </Grid>
            <Grid item sm={12}>
              <QCXFinalDatePickerField
                id="date-picker-data-inicial-de-field"
                key="date-picker-data-inicial-de-field"
                name="dataTransferencia"
                label={t('com.muralis.qcx.data')}
                required
              />
            </Grid>
            <Grid item sm={12}>
              <QCXFinalAlternativeCurrencyField
                id="currency-valor-transferencia"
                key="currency-valor-transferencia"
                name="valorTransferencia"
                label="Valor da Transferência"
              />
            </Grid>
          </Grid>
        </>
      )}
    </QCXFinalFormDialog>
  );
}
