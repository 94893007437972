import { validateQueryParams } from '../../utils/api/api-utils';

import {
  doGetQCXRequest,
  doPutQCXRequest,
  doPostQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import {
  QCX_TITULOS_ENDPOINT,
  QCX_RELATORIO_ENDPOINT,
  QCX_CONTAS_A_PAGAR_ENDPOINT,
  QCX_SOLICITACAO_PAGAMENTO_ENDPOINT,
  QCX_FINANCEIRO_A_PAGAR_ENDPOINT,
  QCX_CANCELAR_ENDPOINT,
  QCX_ESTORNO_ENDPOINT,
} from '../../utils/api/domain/domain-endpoint-utils';

async function fetchAll() {
  return doGetQCXRequest([
    QCX_TITULOS_ENDPOINT,
    QCX_CONTAS_A_PAGAR_ENDPOINT,
  ]);
}

async function generateReportAPagarByDate(params = []) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest([
      QCX_SOLICITACAO_PAGAMENTO_ENDPOINT,
      QCX_RELATORIO_ENDPOINT,
      QCX_CONTAS_A_PAGAR_ENDPOINT,
      ''
    ],
    validatedParams,
    {
      responseType: 'blob',
    });
  }
  return { data: [] };
}

async function cancelar(config = {}) {
  return doPutQCXRequest(
    [
      QCX_TITULOS_ENDPOINT,
      QCX_CANCELAR_ENDPOINT,
    ],
    config
  );
}

async function estornar(config = {}) {
  return doPutQCXRequest(
    [
      QCX_TITULOS_ENDPOINT,
      QCX_ESTORNO_ENDPOINT,
    ],
    config
  );
}

async function transferencia(config = {}) {
  return doPostQCXRequest(
    [
      QCX_SOLICITACAO_PAGAMENTO_ENDPOINT,
      QCX_TITULOS_ENDPOINT,
      QCX_FINANCEIRO_A_PAGAR_ENDPOINT
    ],
    config
  );
}

const contasPagarAPI = {
  fetchAll,
  transferencia,
  cancelar,
  generateReportAPagarByDate,
  estornar,
};

export {
  fetchAll,
  contasPagarAPI,
  cancelar,
  transferencia,
  generateReportAPagarByDate,
  estornar,
};
