import axios from "axios";

const baseUrl = process.env.REACT_APP_QCX_API_BASE_URL! + "/"
    + process.env.REACT_APP_RELATORIOS_API_PROCESSOS_FATURADOS_SALDO!;

const downloadSheet = async (
    token: string,
    unidadeId: number,
    unidadesNegocioID: string[],
    clientesID: string[],
    dataInicio: string,
    dataFim: string,
    emissaoStartDate: string, 
    emissaoEndDate: string    
  ): Promise<void> => {
    const params = new URLSearchParams();
    params.set('tenantId', unidadesNegocioID.join(','));
    params.set('clientesId', clientesID.join(','));
    params.set('inicio', dataInicio);
    params.set('fim', dataFim);
  
    if (emissaoStartDate) {
      params.set('inicioNota', emissaoStartDate);
    }
    if (emissaoEndDate) {
      params.set('fimNota', emissaoEndDate);
    }
  
    let message = null;
  
    try {
      const response = await axios.get(
        `${baseUrl}?${params.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
            quickcomexTenant: unidadeId,
          },
          responseType: "blob",
        }
      );
  
      if (response.status === 200) {
        const file = new File([response.data], `relatorioProcessosFaturadosSaldo.xlsx`, {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
  
        const url = window.URL.createObjectURL(file);
        const a = document.createElement("a");
        a.href = url;
        a.download = file.name;
        a.click();
      } else {
        message = await response.data;
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.data) {
          message = await error.response.data.text();
        } else {
          message = "Erro inesperado";
        }
      } else {
        message = "Erro inesperado";
      }
    }
    if (message) {
      throw new Error(message);
    }
  };  

export { downloadSheet };
