import { useMemo, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import QCXFinalFormDialog from '../../shared-components/dialog/QCXFinalFormDialog';
import { CLOSED_STATUS, isOpenStatus } from '../../utils/hooks/form/dialog/formDialogUtils';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXSelectContaBancariaAutocompleteField from '../../shared-components/select-conta-bancaria/QCXSelectContaBancariaAutocompleteField';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';

export default function QCXConfirmPaymentDateForm({
  handleOnSubmit,
  handleFormStatus,
  formDialogStatus,
  initialValues,
  isSingleItemSelected,
}) {
  const { t } = useTranslation();

  const isFormDialogOpen = useMemo(() => isOpenStatus(formDialogStatus), [formDialogStatus]);

  const handleCloseFormDialog = useCallback(() => {
    handleFormStatus(CLOSED_STATUS);
  }, [handleFormStatus]);

  const handleSubmitAndClose = useCallback(
    async (values) => {
      await handleOnSubmit(values);
      handleCloseFormDialog();
    },
    [handleOnSubmit, handleCloseFormDialog]
  );

  return (
    <QCXFinalFormDialog
      key="form-dialog-confirmar-data-pagamento"
      id="form-dialog-confirmar-data-pagamento"
      title={t('com.muralis.qcx.financeiro.confirmarPagamento')}
      dialogActionsProps={{
        button: {
          submit: {
            description: t('com.muralis.qcx.acoes.confirmar'),
            size: 'small',
            color: 'secondary',
          },
          cancel: {
            description: t('com.muralis.qcx.acoes.cancelar'),
            size: 'small',
          },
        },
      }}
      open={isFormDialogOpen}
      initialValues={initialValues}
      onSubmit={handleSubmitAndClose}
      onClose={handleCloseFormDialog}
    >
      {() => (
        <>
          <Grid>
            <QCXFinalAlternativeCurrencyField
              name="valorParcial"
              label="Valor Recebido"
              required
              disabled={!isSingleItemSelected}
            />
          </Grid>
          <Grid style={{ paddingTop: '1rem' }}>
            <QCXFinalDatePickerField
              id="date-picker-data-pagamento"
              key="date-picker-data-pagamento"
              name="dataPagamento"
              label={t('com.muralis.qcx.dataPagamento')}
              required
            />
          </Grid>
          <Grid style={{ paddingTop: '1rem' }} item xs={12} sm={12} md={12} lg={12}>
            <QCXSelectContaBancariaAutocompleteField
              id="select-conta-bancaria"
              key="select-conta-bancaria"
              name="contaBancariaId"
              label="Conta Bancária"
              filtraControlaSaldo
              required
            />
          </Grid>
        </>
      )}
    </QCXFinalFormDialog>
  );
}
