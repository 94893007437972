import { Button, FormControlLabel, Modal, Radio, RadioGroup } from '@material-ui/core';

type CatalogoProdutosSelecionarTransmissaoModalProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  transmissaoState: string;
  handleTransmissaoStateChange: (event: any) => void;
  handleConfirm: () => void;
};

export default function CatalogoProdutosSelecionarTransmissaoModal({
  isModalOpen,
  closeModal,
  transmissaoState,
  handleTransmissaoStateChange,
  handleConfirm,
}: CatalogoProdutosSelecionarTransmissaoModalProps) {
  return (
    <Modal open={isModalOpen} onClose={closeModal}>
      <div
        style={{
          padding: '20px',
          backgroundColor: 'white',
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '300px',
        }}
      >
        <h2>O que deseja fazer?</h2>
        <RadioGroup value={transmissaoState} onChange={handleTransmissaoStateChange}>
          <FormControlLabel value="catalogo" control={<Radio />} label="Transmitir Catálogo" />
          <FormControlLabel value="operador-estrangeiro" control={<Radio />} label="Vincular Operador Estrangeiro" />
        </RadioGroup>
        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="secondary" onClick={handleConfirm}>
            Confirmar
          </Button>
          <Button variant="contained" color="primary" onClick={closeModal}>
            Cancelar
          </Button>
        </div>
      </div>
    </Modal>
  );
}
