import axios from 'axios';

const baseUrl = process.env.REACT_APP_QCX_API_BASE_URL! + '/' + process.env.REACT_APP_RELATORIOS_API_MOVIMENTACAO!; // Updated API endpoint for Movimentacao

const generateSpreadsheetMovimentacao = async (
  dataInicio: string,
  dataFim: string,
  token: string,
  unidadeId: number, // Tenant (Selected Unit)
  unidadesNegocioIDs: number[], // Business Unit IDs array
  fornecedoresIds: number[], // Fornecedor IDs array
  bancoId: number | null,
  origem: string
) => {
  const params = new URLSearchParams();
  params.set('unidadesNegocioID', unidadesNegocioIDs.join(',')); // Join business unit IDs as a comma-separated string
  params.set('fornecedoresId', fornecedoresIds.join(',')); // Join fornecedor IDs as a comma-separated string
  params.set('inicio', dataInicio);
  params.set('fim', dataFim);

  if (bancoId) {
    params.set('bancoId', bancoId.toString());
  }

  if (origem) {
    params.set('origem', origem);
  }

  let message = null;

  try {
    const response = await axios.get(`${baseUrl}?${params.toString()}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        quickcomexTenant: unidadeId,
      },
      responseType: 'blob',
    });

    if (response.status === 200) {
      // Successful response
      const file = new File([response.data], 'relatorioMovimentacao.xlsx', {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.name;
      a.click();
    } else {
      message = await response.data.text();
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data) {
        message = await error.response.data.text();
      } else {
        message = 'Erro inesperado';
      }
    } else {
      message = 'Erro inesperado';
    }
  }
  return message;
};

export { generateSpreadsheetMovimentacao };
