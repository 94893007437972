import PageLayout from '../../../common/layouts/pageLayout';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import { Button, FormControl, Grid, TextField } from '@mui/material';
import FormHeaderMedium from '../../../common/components/forms/formHeaderMedium';
import { makeStyles } from '@material-ui/core';
import MultiSelectStyled from "../../../common/components/input/multiSelectStyled";
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useListClientes } from "../../../common/hooks/useListClientes";
import QCXSelectFornecedorAutocomplete from '../../../../shared-components/select-follow-up/QCXSelectFollowUpAutocompleteField';
import QCXFinalAlternativeCurrencyField from '../../../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import { Form, Field, useFormState } from 'react-final-form';
import { BRAZILIAN } from '../../../../utils/hooks/form/field/mask-types';
import keycloak from '../../../../components/keycloak';
import { KEYCLOAK_TOKEN_TIMEOUT } from '../../../../App';
import { viVN } from '@mui/x-data-grid';
import axios from 'axios';
import { selectUnidadeSelecionada } from '../../../../features/usuario-logado/usuarioLogadoSlice';
import { useDispatch, useSelector } from 'react-redux';
import { MultiSelectFornecedor } from '../../financeiro/relatorioMovimentacao/MultiSelectFornecedor';
import { fetchAll as fetchAllFornecedores } from '../../../../features/fornecedor-favorecido/FornecedorAPI';
import { setErrorFeedback } from '../../../../features/feedback/feedbackSlice';
import { isValidDate } from '../../../../utils/validators/field/validator';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        gap: '10px', // Ajuste o gap para controlar a distância entre os inputs
    },
    input: {
        width: '50%',
        marginRight: '1px', // Reduz a margem direita para aproximar os inputs
        borderRadius: '4px',
    },
    button: {
        borderRadius: '4px',
    },
});

interface Data {
    dataPagamento: string;
}

interface Valor {
    valor_pagamento : number
}

interface ControlledValue {
    id: string | number;
    value: string;
}

const baseUrl =
  process.env.REACT_APP_QCX_API_BASE_URL! +
  '/' +
  'titulo/conta-azul';

const ContaAzulPage = () => {

    const classes = useStyles();
    
    const dispatch = useDispatch();

    const [datas, setDatas] = useState<Data>({
        dataPagamento: ''
    });

    const [valor, setvalor] = useState<Valor>({
        valor_pagamento : 0
    })

    const { token } = keycloak;

    const selectedUnit = useSelector(selectUnidadeSelecionada);

    const [clientes] = useListClientes();

    const [fornecedores, setFornecedores] = useState<any>([]);

    const [selectedClients, setSelectedClients] = useState<ControlledValue[]>([]);

    const [selectedFornecedores, setSelectedFornecedores] = useState<ControlledValue[]>([]);

    const handleClientsChange = (event: SyntheticEvent<Element, Event>, value: ControlledValue[] | null) => {
        
        const newSelectedClients: ControlledValue[] = [];

        value?.forEach( (v) => {

            const selectedClient = clientes.find((cliente) => cliente.id === v.id);

            if (selectedClient && selectedClient.id && !selectedClients.some((client) => client.id === selectedClient.id)) {
                newSelectedClients.push({
                  id: selectedClient.id.toString(),
                  value: `(${selectedClient.pessoa!.cnpj}) ${selectedClient.pessoa!.nome}`,
                });
            }

        });

        setSelectedClients(newSelectedClients);
    }

    const handleFornecedoresChange = (event: SyntheticEvent<Element, Event>, value: ControlledValue[] | null) => {

        const newSelectedFornecedores : ControlledValue[] = [];

        value?.forEach( (v) => {
            
            const selectedFornecedor = fornecedores.find( (fornecedor : any) => fornecedor.id == v.id);

            if(selectedFornecedor && selectedFornecedor.id){
                newSelectedFornecedores.push({
                    id: selectedFornecedor.id,
                    value : `(${selectedFornecedor.pessoa!.cnpj}) ${selectedFornecedor.pessoa!.nome}`
                })
            }

        })

        setSelectedFornecedores(newSelectedFornecedores);

    }

    function isDataValida(data : string) {

        try {

            const date = new Date(data);
    
            return  !isNaN(date.getDay());
            
        } catch (e) {
            return false; 
        }
    }

    function isValidNumber(value : string) {
        return !isNaN(Number(value));
    }

    const handleSubmit = async (values : any) => {

        keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);

        const { valor_pagamento } = values;

        let valor_pagamento_string = valor_pagamento as string;

        valor_pagamento_string = valor_pagamento_string.replaceAll('.','');
        valor_pagamento_string = valor_pagamento_string.replaceAll(',','.');
        
        const params = new URLSearchParams();

        if (isValidNumber(valor_pagamento_string)) {
            params.append('valor', valor_pagamento_string);
        }

        if (isDataValida(datas.dataPagamento)) {
            params.set("dataPagamento", datas.dataPagamento);    
        }

        if (selectedClients.length > 0) {
            params.append('clientes', selectedClients.join(','));    
        }

        if (selectedFornecedores.length > 0) {
            params.append('fornecedoresId',selectedFornecedores.join(','));
        }

        if (!!selectedUnit) {
            params.append('tenentId',selectedUnit);
        }

        try {
            const response = await axios.get(`${baseUrl}?${params.toString()}`, {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
                quickcomexTenant: selectedUnit,
              },
              responseType: 'blob',
            });
        
            if (response.status === 200) {
              const blob = new Blob([response.data], {
                type: response.headers['content-type'],
              });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = 'relatorioDespesasPagas.xlsx';
              a.click();
              window.URL.revokeObjectURL(url);
            } else {
              const errorText = await response.data.text();
              return errorText;
            }
          } catch (error) {
            let message = 'Erro ao gerar o relatório.';
        
            if (axios.isAxiosError(error)) {
              if (error.response && error.response.data) {
                message = await error.response.data.text();
              }
            }
        
            return message;
          }
        
          return null;
        
    }

    const handleDataPagamento = (e: ChangeEvent<HTMLInputElement>) => {
        setDatas({ dataPagamento : e.target.value })
    }

    const handleValor = (e: ChangeEvent<HTMLInputElement>) => {
        setvalor({ valor_pagamento : +e.target.value })
    }

    useEffect(() => {
        const fetchFornecedores = async () => {
          try {
            const response = await fetchAllFornecedores();
            if (response.status === 200) {
              setFornecedores(response.data);
            } else {
              dispatch(
                setErrorFeedback({
                  message: 'Erro ao buscar fornecedores.',
                })
              );
            }
          } catch (error) {
            dispatch(
              setErrorFeedback({
                message: 'Erro ao buscar fornecedores.',
              })
            );
          }
        };
      
        fetchFornecedores();
      }, [dispatch]);

    return (
        <PageLayout 
            title="Relatório Conta Azul" 
            icon={<HistoryEduIcon color={'secondary'} />}>
            <Form onSubmit={handleSubmit}> 
                {
                    ({handleSubmit, form}) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <FormHeaderMedium>Selecione os Filtros :</FormHeaderMedium>
                            </Grid>
                            <Grid item sm={12} style={{ margin: '25px 0 0 0' }}>    
                                    <FormControl fullWidth>
                                        <MultiSelectStyled
                                            options={fornecedores.map((client : any) => ({
                                                id: client?.id,
                                                value: `(${client.pessoa!.cnpj}) ${client.pessoa!.nome}`
                                            } as ControlledValue))}
                                            onChangeAction={handleFornecedoresChange}
                                            label={"Fornecedores"}
                                        ></MultiSelectStyled>
                                    </FormControl>
                            </Grid>
                            <Grid item sm={12} style={{ margin: '25px 0 0 0' }}>
                                    <FormControl fullWidth>
                                        <MultiSelectStyled
                                            options={clientes.map((client) => ({
                                                id: client.id,
                                                value: `(${client.pessoa!.cnpj}) ${client.pessoa!.nome}`
                                            } as ControlledValue))}
                                            onChangeAction={handleClientsChange}
                                            label={"Clientes"}
                                        ></MultiSelectStyled>
                                    </FormControl>
                            </Grid>
                            <Grid item sm={6} style={{ margin: '25px 0 0 0' }}>
                                <QCXFinalAlternativeCurrencyField
                                    id="text-field-valor-minimo"
                                    key="text-field-valor-minimo"
                                    name="valor_pagamento"
                                    label="Valor"
                                    maxLength={10}
                                    intl={BRAZILIAN}
                                    scale={4}
                                    required={undefined} 
                                    validate={undefined}
                                    submitSucceeded={undefined}
                                />
                            </Grid>
                            <Grid item sm={6} style={{ margin: '25px 0 0 0' }}>
                                    <TextField
                                            color='primary'
                                            label="Data de Pagamento"
                                            type="date"
                                            value={datas.dataPagamento}
                                            InputLabelProps={{ shrink: true }}
                                            style={ { width:'100%'}}
                                            className={classes.input}
                                            onChange={handleDataPagamento}
                                        />
                            </Grid>
                        
                            <Grid item xs={12} style={{ margin: '25px 0 0 0' }}>
                                <Button
                                variant="contained"
                                color="secondary"
                                type="submit"
                                >
                                Gerar Relatório
                                </Button>
                            </Grid>
                        </form>
                    )
                }
            </Form>
        </PageLayout>
    );

}


export default ContaAzulPage;