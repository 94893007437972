import axios from 'axios';

const baseUrl = process.env.REACT_APP_QCX_API_BASE_URL! + '/' + process.env.REACT_APP_RELATORIOS_API_CONTAS_RECEBER!; // Update the endpoint for Contas a Receber

export const generateContasAReceberReport = async (
  token: string,
  unidadeId: string,
  tenantIds: string[],
  clientIds: string[],
  startDateISO: string,
  endDateISO: string,
  origem: string,      // Added origem parameter
  bancoId: number | null // Added bancoId parameter
): Promise<string | null> => {
  const params = new URLSearchParams();
  params.set('tenantId', tenantIds.join(','));
  if (clientIds.length > 0) {
    params.set('clientesId', clientIds.join(','));
  }
  // Date range
  const startDateRange = startDateISO.split('T')[0];
  const endDateRange = endDateISO.split('T')[0];
  const dateRange = startDateRange + ';' + endDateRange;
  params.set('insertionDateRange', dateRange);

  // Add origem if provided
  if (origem) {
    params.set('origem', origem);
  }

  // Add bancoId if provided
  if (bancoId) {
    params.set('bancoId', bancoId.toString());
  }

  try {
    const response = await axios.get(`${baseUrl}?${params.toString()}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        quickcomexTenant: unidadeId,
      },
      responseType: 'blob',
    });

    if (response.status === 200) {
      const file = new File([response.data], 'relatorioContasAReceber.xlsx', {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.name;
      a.click();
    } else {
      return await response.data.text();
    }
  } catch (error) {
    let message = 'Erro ao gerar o relatório.';

    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data) {
        message = await error.response.data.text();
      }
    }

    return message;
  }

  return null;
};
