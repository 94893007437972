import { Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import QCXFinalField from '../../../../../shared-components/final-field/QCXFinalField';
import QCXFinalTextField from '../../../../../shared-components/final-text-field/QCXFinalTextField';

export default function FollowUpAdvancedFilterForm() {
    const { t } = useTranslation();

    return (
        <Box style={{ minWidth: '500px' }}>
            <Grid container spacing={2}>
                <Grid item sm={6}>
                    <QCXFinalTextField
                        id="text-field-vinculo-referencia-cliente"
                        key="text-field-vinculo-referencia-cliente"
                        name="referencia"
                        label={t('com.muralis.qcx.referenciaCliente')}
                        maxLength={100}
                    />
                </Grid>
            </Grid>
        </Box >
    );
}
