// contasPagarPorDataVencimentoPage.helpers.ts

import axios from 'axios';

const baseUrl =
  process.env.REACT_APP_QCX_API_BASE_URL! + '/' + process.env.REACT_APP_RELATORIOS_API_CONTAS_PAGAR!;

export const generateContasPagarReport = async (
  token: string,
  unidadeId: string,
  tenantIds: string[],
  clientIds: string[],
  fornecedorId: string, // Updated parameter
  startDate: string,
  endDate: string,
  origem: string,
  bancoId: number | null
): Promise<string | null> => {
  const params = new URLSearchParams();
  params.set('tenantId', tenantIds.join(','));

  if (clientIds.length > 0) {
    params.set('clientesId', clientIds.join(','));
  }

  if (fornecedorId) {
    params.set('fornecedorId', fornecedorId); // Updated to singular 'fornecedorId'
  }

  if (origem) {
    params.set('origem', origem);
  }

  if (bancoId) {
    params.set('bancoId', bancoId.toString());
  }

  const dateRange = `${startDate};${endDate}`;
  params.set('insertionDateRange', dateRange);

  try {
    const response = await axios.get(`${baseUrl}?${params.toString()}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        quickcomexTenant: unidadeId,
      },
      responseType: 'blob',
    });

    if (response.status === 200) {
      const file = new File([response.data], 'relatorioContasPagar.xlsx', {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.name;
      a.click();

      return null;
    } else {
      const errorMessage = await response.data.text();
      return errorMessage || 'Erro ao gerar o relatório.';
    }
  } catch (error) {
    let message = 'Erro ao gerar o relatório.';

    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data) {
        try {
          const errorMsg = await error.response.data.text();
          message = errorMsg || message;
        } catch (e) {
          // Fallback to default message
        }
      }
    }

    return message;
  }
};
