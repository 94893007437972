import { Grid, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { cfopOperacaoFiscalActions } from '../../features/cfop-operacoes-fiscais/cfopOperacoesFiscaisSlice';
import { AUTO_LOAD_CONTROL } from '../../features/config-control';
import { fetchByFilter as fetchNaladiNccaByFilter } from '../../features/naladi-ncca/naladiNccaAPI';
import { naladiNccaActions } from '../../features/naladi-ncca/naladiNccaSlice';
import { fetchByFilter as fetchNaladiShByFilter } from '../../features/naladi-sh/naladiShAPI';
import { naladiShActions } from '../../features/naladi-sh/naladiShSlice';
import { fetchById as fetchNcmSubItemById } from '../../features/ncm-subitem/ncmSubitemAPI';
import { ncmSubitemActions } from '../../features/ncm-subitem/ncmSubitemSlice';
import { paisActions } from '../../features/pais/paisSlice';
import { unidadeDeMedidaActions } from '../../features/unidade-medida/unidadeDeMedidaSlice';
import QCXBox from '../../shared-components/box/QCXBox';
import QCXSelectCFOPOperacoesFiscaisAutocomplete from '../../shared-components/final-autocomplete-select-cfop-operacoes-fiscais-field/QCXSelectCFOPOperacoesFiscaisAutocomplete';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalNumericDecimalField from '../../shared-components/final-numeric-decimal-field/QCXFinalNumericDecimalField';
import QCXFinalPercentualField from '../../shared-components/final-percentual-field/QCXFinalPercentualField';
import QCXFinalMultilineTextField from '../../shared-components/final-text-field/QCXFinalMultilineTextField';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectFormaCalculoAutocomplete from '../../shared-components/select-forma-calculo/QCXSelectFormaCalculoAutocomplete';
import QCXSelectNaladiNccaAutocomplete from '../../shared-components/select-naladi-ncca/QCXSelectNaladiNccaAutocomplete';
import QCXSelectNaladiShAutocomplete from '../../shared-components/select-naladi-sh/QCXSelectNaladiShAutocomplete';
import QCXSelectNcmSubItemAutocomplete from '../../shared-components/select-ncm-subitem/QCXSelectNcmSubItemAutocomplete';
import QCXSelectUnidadeMedidaAutocomplete from '../../shared-components/select-unidade-medida/QCXSelectUnidadeMedidaAutocomplete';
import { TIPO_DUIMP } from '../../utils/general/catalogo-produtos/catalogoProdutosUtils';
import { isCurrentOrFutureDate, maxValue, required as requiredValidator } from '../../utils/validators/field/validator';
import QCXRelacaoFornecedorFieldGroup from '../relacao-fornecedor/QCXRelacaoFornecedorFieldGroup';
import {
  RELACAO_FABRICANTE_DESCONHECIDO,
  RELACAO_FABRICANTE_EXPORTADOR,
  RELACAO_FABRICANTE_NAO_EXPORTADOR,
} from '../relacao-fornecedor/relacao-fornecedor-utils';

export default function QCXCatalogoProdutosTecnicalDataForm({
  initialValues,
  handleSubmit,
  isConsult,
  controlComponentProps,
  ...restProps
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [isFirstFormLoadValues, setIsFirstFormLoadValues] = useState(true);

  useEffect(() => {
    dispatch(unidadeDeMedidaActions.changeControlTo(AUTO_LOAD_CONTROL));
    dispatch(paisActions.changeControlTo(AUTO_LOAD_CONTROL));
    dispatch(naladiShActions.changeControlTo(AUTO_LOAD_CONTROL));
    dispatch(naladiNccaActions.changeControlTo(AUTO_LOAD_CONTROL));
    dispatch(ncmSubitemActions.changeControlTo(AUTO_LOAD_CONTROL));
    dispatch(cfopOperacaoFiscalActions.changeControlTo(AUTO_LOAD_CONTROL));
  }, []);

  useEffect(() => {
    if (isConsult) {
      setIsFirstFormLoadValues(true);
    }
  }, [isConsult]);

  const ncmSubitemOnChange = useCallback(
    (form) => async (value) => {
      const responseNcmSubItem = await fetchNcmSubItemById(value);
      if (responseNcmSubItem?.status === 200 && !isEmpty(responseNcmSubItem) && responseNcmSubItem?.data) {
        const findNcm = responseNcmSubItem?.data;
        // if (findNcm) {
        //   const responseNaladiNcca = await fetchNaladiNccaByFilter([{ name: 'code', value: findNcm?.code }]);
        //   if (responseNaladiNcca?.status === 200 && !isEmpty(responseNaladiNcca) && responseNaladiNcca?.data) {
        //     const findNaladiNcca = responseNaladiNcca?.data[0];
        //     const responseNaladiSh = await fetchNaladiShByFilter([{ name: 'code', value: findNcm?.code }]);
        //     if (responseNaladiSh?.status === 200 && !isEmpty(responseNaladiSh) && responseNaladiSh?.data) {
        //       const findNaladiSh = responseNaladiSh?.data[0];
        //       if (isValid(findNaladiNcca?.id)) {
        //         form.change('naladiNcca.id', findNaladiNcca?.id);
        //       }
        //       if (isValid(findNaladiSh?.id)) {
        //         form.change('naladiSh.id', findNaladiSh?.id);
        //       }
        //     }
        //   }
        // }

        form.change('ncm.id', findNcm?.id);
        form.change('ncm.unidadeDeMedida.description', findNcm?.unidadeDeMedida?.description || '');
      }
    },
    [fetchNcmSubItemById, fetchNaladiNccaByFilter, fetchNaladiShByFilter]
  );

  const handleEspecificacaoChange = (form) => (value) => {
    if (!isEmpty(value)) {
      const blockedCharactersPattern = /[\u0300-\u036f\u00b4\u0060\u0026\u0023\u007e\u005e]/g;
      const parsed = String(value).normalize('NFD').replace(blockedCharactersPattern, '');

      form.change('especificacao', parsed);
    }
  };

  const handleRelacaoFornecedorChange = (form, values) => (value) => {
    if (isFirstFormLoadValues) {
      setIsFirstFormLoadValues(false);
      return;
    }
    if (value === RELACAO_FABRICANTE_EXPORTADOR) {
      form.change('paisOrigem', undefined);
      form.change('fabricante', undefined);
      form.change('fabricante.id', values?.exportador?.id);
      return;
    }
    if (value === RELACAO_FABRICANTE_NAO_EXPORTADOR) {
      form.change('paisOrigem', undefined);
      form.change('fabricante', undefined);
      return;
    }
    if (value === RELACAO_FABRICANTE_DESCONHECIDO) {
      form.change('fabricante', undefined);
      return;
    }

    form.restart();
    form.initialize({
      ...values,
      relacao: undefined,
      exportador: undefined,
      fabricante: undefined,
      paisOrigem: undefined,
    });
  };

  const handleExportadorIdChange = (form, values) => (value) => {
    if (values?.relacao === RELACAO_FABRICANTE_EXPORTADOR) {
      form.change('fabricante', undefined);
      form.change('fabricante.id', value);
      return;
    }
    if (values?.relacao === RELACAO_FABRICANTE_NAO_EXPORTADOR) {
      if (values?.fabricante?.id !== value) {
        return;
      }
      form.change('fabricante', undefined);
      return;
    }
    if (values?.relacao === RELACAO_FABRICANTE_DESCONHECIDO) {
      form.change('fabricante', undefined);
      return;
    }
    form.change('exportador', undefined);
  };

  const listenerList = useMemo(
    () => [
      {
        name: 'ncm.id',
        fn: ncmSubitemOnChange,
      },
      {
        name: 'especificacao',
        fn: handleEspecificacaoChange,
      },
      {
        name: 'relacao',
        fn: handleRelacaoFornecedorChange,
      },
      {
        name: 'exportador.id',
        fn: handleExportadorIdChange,
      },
    ],
    [ncmSubitemOnChange, handleEspecificacaoChange, handleRelacaoFornecedorChange, handleExportadorIdChange]
  );

  const currentDate = moment().toDate();

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      onChangeListeners={listenerList}
      {...restProps}
    >
      {({ values }) => (
        <>
          <Typography variant="h6" color="primary">
            <QCXBox fontWeight="fontWeightBold" pt={5}>
              {t('com.muralis.qcx.dadosTecnicos').toUpperCase()}
            </QCXBox>
          </Typography>
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            <Grid item xs={12} sm={6} md={3}>
              <QCXFinalTextField
                id="text-partnumber-field"
                key="text-partnumber-field"
                name="partnumber"
                validate={requiredValidator}
                label={t('com.muralis.qcx.item.partNumber')}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <QCXFinalTextField
                id="text-codigo-secundario-field"
                key="text-codigo-secundario-field"
                name="codigoSecundario"
                label="Código Secundário"
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <QCXFinalTextField
                id="text-codigo-integracao-field"
                key="text-codigo-integracao-field"
                name="codigoIntegracao"
                validate={undefined}
                label={t('com.muralis.qcx.codigoIntegracao')}
                maxLength={10}
                disabled={isConsult}
                required={false}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <QCXFinalTextField
                id="text-versao-field"
                key="text-versao-field"
                name="versao"
                validate={undefined}
                label={t('com.muralis.qcx.versao')}
                maxLength={10}
                disabled={isConsult}
                required={false}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <QCXFinalTextField
                id="text-apelido-field"
                key="text-apelido-field"
                name="apelido"
                validate={requiredValidator}
                label={t('com.muralis.qcx.apelido')}
                maxLength={50}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <QCXFinalTextField
                id="text-nome-field"
                key="text-nome-field"
                name="descricaoResumida"
                label={t('com.muralis.qcx.mercadoria.descricaoResumida')}
                maxLength={100}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <QCXSelectUnidadeMedidaAutocomplete
                id="autocomplete-select-unidade-medida-field"
                key="autocomplete-select-unidade-medida-field"
                name="unidadeDeMedida.id"
                label={t('com.muralis.qcx.mercadoria.unidadeMedidaMercadoria')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <QCXSelectNcmSubItemAutocomplete
                id="autocomplete-select-field-ncm-id"
                key="autocomplete-select-field-ncm-id"
                name="ncm.id"
                label={t('com.muralis.qcx.NCM.label')}
                initialValues={values}
                disabled={isConsult}
                fieldProps={{ validate: requiredValidator }}
                required
                // onBlur={ncmSubitemOnBlur(form, values)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <QCXFinalAlternativeCurrencyField
                id="numeric-decimal-valor-unitario-field"
                key="numeric-decimal-valor-unitario-field"
                name="valorUnitarioMoeda"
                label={t('com.muralis.qcx.valorUnitario')}
                disabled={isConsult}
                scale={7}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <QCXSelectNaladiShAutocomplete
                id="autocomplete-select-field-naladiSh-id"
                key="autocomplete-select-field-naladiSh-id"
                name="naladiSh.id"
                label={t('com.muralis.qcx.naladi.codigoNaladiSH')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXSelectFormaCalculoAutocomplete
                id="autocomplete-select-tipo-calculo-field"
                key="autocomplete-select-tipo-calculo-field"
                name="tipoCalculo"
                label={t('com.muralis.qcx.formaCalculo')}
                initialValues={values}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                required
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <QCXSelectNaladiNccaAutocomplete
                id="autocomplete-select-field-naladi-ncca-id"
                key="autocomplete-select-field-naladi-ncca-id"
                name="naladiNcca.id"
                label={t('com.muralis.qcx.naladi.codigoNaladiNCCA')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <QCXFinalPercentualField
                id="porcentagem-icms-field"
                key="porcentagem-icms-field"
                name="icms"
                label={t('com.muralis.qcx.ICMS.label')}
                validate={maxValue(99.99)}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <QCXFinalTextField
                id="text-destaque-ncm-field"
                key="text-destaque-ncm-field"
                name="destaqueNcm"
                label={t('com.muralis.qcx.NCM.destaqueNCM')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <QCXFinalTextField
                id="text-gtin-field"
                key="text-gtin-field"
                name="gtin"
                label="GTIN"
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <QCXFinalNumericDecimalField
                id="numeric-decimal-peso-liquido-field"
                key="numeric-decimal-peso-liquido-field"
                name="pesoLiquido"
                label="Peso Liquido Unitário"
                disabled={isConsult}
                scale={7}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <QCXFinalTextField
                id="text-lote-field"
                key="text-lote-field"
                name="lote"
                label={t('com.muralis.qcx.lote')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <QCXFinalDatePickerField
                id="date-picker-data-validade-field"
                key="date-picker-data-validade-field"
                name="dataValidade"
                label={t('com.muralis.qcx.dataValidade')}
                disabled={isConsult}
                minDate={currentDate}
                validate={!isConsult && isCurrentOrFutureDate}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <QCXFinalTextField
                id="text-unidade-na-medida-field"
                key="text-unidade-na-medida-field"
                name="ncm.unidadeDeMedida.description"
                label={t('com.muralis.qcx.unidadeMedida.unidadeMedidaEstatistica')}
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <QCXFinalTextField
                id="text-area-field"
                key="text-area-field"
                name="area"
                label={t('com.muralis.qcx.area')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <QCXFinalTextField
                id="text-marca-field"
                key="text-marca-field"
                name="marca"
                label={t('com.muralis.qcx.marca')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <QCXFinalTextField
                id="text-modelo-field"
                key="text-modelo-field"
                name="modelo"
                label={t('com.muralis.qcx.modelo')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <QCXFinalTextField
                id="text-numero-serie-field"
                key="text-numero-serie-field"
                name="numeroSerie"
                label={t('com.muralis.qcx.numeroSerie')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <QCXFinalDatePickerField
                id="date-picker-fabricacao-field"
                key="date-picker-fabricacao-field"
                name="anoFabricacao"
                label={t('com.muralis.qcx.anoFabricacao')}
                views={['year']}
                format="YYYY"
                placeholder="YYYY"
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={6}>
              <QCXSelectCFOPOperacoesFiscaisAutocomplete
                id="text-cfop-operacoes-fiscais-field"
                key="text-cfop-operacoes-fiscais-field"
                name="operacaoFiscal"
                label={t('com.muralis.qcx.CFOP.labelExtenso')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={3}>
              <QCXFinalTextField
                id="text-numero-serie-field"
                key="text-numero-serie-field"
                name="quebraAuxiliar"
                label="Quebra Auxiliar"
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3} style={{ paddingTop: '1.5vh' }}>
              <QCXFinalCheckboxField
                id="checkbox-exTarifario-field"
                key="checkbox-exTarifario-field"
                name="exTarifario"
                label={t('com.muralis.qcx.exTarifario')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} style={{ paddingTop: '1.5vh' }}>
              <QCXFinalCheckboxField
                id="checkbox-tipo-field"
                key="checkbox-tipo-field"
                name="tipo"
                value={TIPO_DUIMP}
                label={t('com.muralis.qcx.DIDUIMP.DUIMP')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} style={{ paddingTop: '1.5vh' }}>
              <QCXFinalCheckboxField
                id="checkbox-tipo-field"
                key="checkbox-tipo-field"
                name="necessitaLI"
                label="Necessita LI"
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <QCXFinalMultilineTextField
                id="text-especificacao-multiline-field"
                key="text-especificacao-multiline-field"
                name="especificacao"
                label={t('com.muralis.qcx.especificacao.especificacaoMercadoria')}
                disabled={isConsult}
                rows={5}
                maxLength={3900}
                helperText={t('com.muralis.qcx.validacao.caracteresEspeciaisNaoPermitidos')}
                validate={requiredValidator}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <QCXFinalMultilineTextField
                id="text-descricao-danfe-multiline-field"
                key="text-descricao-danfe-multiline-field"
                name="descricaoDanfe"
                label="Descrição da DANFE"
                disabled={isConsult}
                rows={2}
                maxLength={3900}
                helperText={t('com.muralis.qcx.validacao.caracteresEspeciaisNaoPermitidos')}
              />
            </Grid>
            <QCXRelacaoFornecedorFieldGroup
              isConsult={isConsult}
              relacao={values?.relacao}
              fabricante={values?.fabricante}
              exportador={values?.exportador}
              initialValues={initialValues}
              required
              manyFornecedores
              {...restProps}
            />
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
