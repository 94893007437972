import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { useHistory } from 'react-router';
import { Button, Grid, SelectChangeEvent } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, makeStyles, MenuItem } from '@material-ui/core';
import { setErrorFeedback, setSuccessFeedback } from '../../../../features/feedback/feedbackSlice';
import { generateSpreadsheetMovimentacao } from './relatorioMovimentacaoPage.helpers'; // New helper for generating the report
import { useUnidadeNegocioGuard } from '../../../common/hooks/useUnidadeNegocioGuard';

import {
  selectUnidadeSelecionada,
  selectUnidadesDeNegocioAssociadas,
} from '../../../../features/usuario-logado/usuarioLogadoSlice';
import { GridItemModalProps } from '../../../common/components/cardsGrid/cardsModalGrid';
import { Field, Form } from 'react-final-form';
import QCXFinalDatePickerField from '../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import theme from '../../../common/themes/theme';
import { fetchAllAsync as fetchAllFornecedores } from '../../../../features/fornecedor-favorecido/FornecedorSlice';
import { useTranslation } from 'react-i18next';
import QCXSelectContaBancariaAutocompleteField from '../../../../shared-components/select-conta-bancaria/QCXSelectContaBancariaAutocompleteField';
import { MultiSelectFornecedor } from './MultiSelectFornecedor';
import MultiSelectFormInput from '../../../common/components/forms/MultiSelectFormInput';
import QCXTextField from '../../../../shared-components/text-field/QCXTextField';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      minWidth: '50vw',
    },
  },
  inputLabel: {
    backgroundColor: 'white',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    gap: '10px',
  },
  input: {
    height: 40,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  button: {
    borderRadius: '4px',
  },
});

const RelatorioMovimentacaoPage = ({ title, isOpen, closeModal }: GridItemModalProps) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { keycloak } = useKeycloak();
  const { token } = keycloak;

  keycloak.updateToken(300);

  const selectedUnit = useSelector(selectUnidadeSelecionada);
  const businessUnits = useSelector(selectUnidadesDeNegocioAssociadas);
  const [origem, setOrigem] = useState(''); 

  const businessUnitsOptions = businessUnits.map((unit: any) => ({
    id: unit.id,
    value: unit.pessoa.nomeResumido ?? unit.pessoa.nome,
  }));

  const [datas, setDatas] = useState({
    dataInicio: '',
    dataFim: '',
  });
  useUnidadeNegocioGuard();

  const [selectedBusinessUnits, setSelectedBusinessUnits] = useState<number[]>([]);
  const [selectedFornecedores, setSelectedFornecedores] = useState<number[]>([]);

  const [bancoId, setBancoId] = useState<number | null>(null);

  const handleChangeBanco = (_event: any, option: any) => {
    setBancoId(option?.id);
    console.log('bancoId', option?.id);
  };

  useEffect(() => {
    dispatch(fetchAllFornecedores());
  }, []);

  const validarDatas = (datas: { dataInicio: string; dataFim: string }): boolean => {
    if (!datas.dataInicio || !datas.dataFim) {
      return false;
    }
    const dataInicio = new Date(datas.dataInicio);
    const dataFim = new Date(datas.dataFim);
    return dataInicio <= dataFim;
  };

  const handleChangeDataInicio = (isoDate: string) => {
    const value = isoDate.split('T')[0];

    setDatas((prevDatas) => ({ ...prevDatas, dataInicio: value }));
  };

  const handleOrigemChange = (event: SelectChangeEvent<string>) => {
    setOrigem(event.target.value);
  };

  const handleChangeDataFim = (isoDate: string) => {
    const value = isoDate.split('T')[0];

    setDatas((prevDatas) => ({ ...prevDatas, dataFim: value }));
  };

  const handleSubmit = useCallback(async () => {
    keycloak.updateToken(300);

    if (!validarDatas(datas)) {
      dispatch(
        setErrorFeedback({
          message: 'Preencha as datas de início e fim corretamente.',
        })
      );
      return;
    }

    if (token && selectedUnit) {
      try {
        const error = await generateSpreadsheetMovimentacao(
          datas.dataInicio,
          datas.dataFim,
          token,
          selectedUnit, // Tenant
          selectedBusinessUnits, // Business Units
          selectedFornecedores, // Fornecedores
          bancoId,
          origem  // Pass 'origem' to the helper function
        );
        if (error != null) {
          dispatch(
            setErrorFeedback({
              message: error ? error : 'Erro ao gerar a planilha.',
            })
          );
        } else {
          dispatch(
            setSuccessFeedback({
              message: 'Planilha gerada com sucesso!',
            })
          );
        }
      } catch (error) {
        dispatch(
          setErrorFeedback({
            message: 'Erro inesperado ao gerar o relatório.',
          })
        );
      }
    }
    keycloak.updateToken(300);
    history.push('/financeiro/relatorios');
  }, [datas, token, selectedUnit, selectedBusinessUnits, selectedFornecedores, dispatch, keycloak, history, origem]);

  return (
    <Dialog className={classes.root} open={isOpen} onClose={closeModal}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Form onSubmit={() => {}}>
          {() => (
            <form>
              <Grid container spacing={2}>
                {/* Unidade de Negócio multi-select */}
                <Grid item md={6}>
                  <MultiSelectFormInput
                    name="unidades-de-negocio"
                    label="Unidade de negócio"
                    options={businessUnitsOptions}
                    disablePortal={false}
                    onChangeCallback={(selected) => setSelectedBusinessUnits(selected.map((s) => s.id as number))}
                  />
                </Grid>

                {/* Fornecedor multi-select */}
                <Grid item xs={12} sm={12} md={6}>
                  <QCXSelectContaBancariaAutocompleteField
                    id="autocomplete-select-conta-bancaria-impostos-estaduais-field"
                    key="autocomplete-select-conta-bancaria-impostos-estaduais-field"
                    name="contaBancariaImpostosEstaduais"
                    label='Conta bancária'
                    initialValues={undefined}
                    filtraControlaSaldo={true}
                    onChange={handleChangeBanco}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <QCXTextField
                    style={{ width: '100%' }}
                    label="Origem"
                    key="select-origem-movimentacao"
                    id="select-origem-movimentacao"
                    select
                    value={origem}
                    onChange={handleOrigemChange}
                    {...({} as any)}
                  >
                    <MenuItem value="">Todos</MenuItem> {/* Added 'Todos' option */}
                    <MenuItem value="OPERACIONAL">OPERACIONAL</MenuItem>
                    <MenuItem value="ADMINISTRATIVA">ADMINISTRATIVA</MenuItem>
                  </QCXTextField>
                </FormControl>
              </Grid>

                <Grid item xs={12}>
                  <MultiSelectFornecedor
                    onChangeCallback={(selected) => setSelectedFornecedores(selected.map((s) => s.id as number))}
                  />
                </Grid>

                {/* Date fields */}
                <Grid item xs={6} style={{ marginBottom: '25px' }}>
                  <Field
                    name="startDate"
                    render={({ input }) => (
                      <QCXFinalDatePickerField
                        id="contas-pagar-vencimento-start-date"
                        name={input.name}
                        label={'Data de Início'}
                        required={true}
                        format="DD/MM/YYYY"
                        placeholder="DD/MM/YYYY"
                        onChange={(date: any) => {
                          handleChangeDataInicio(date.toISOString());
                          input.onChange(date);
                        }}
                        validate={undefined}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} style={{ marginBottom: '25px' }}>
                  <Field
                    name="endDate"
                    render={({ input }) => (
                      <QCXFinalDatePickerField
                        id="contas-pagar-vencimento-end-date"
                        name={input.name}
                        label={'Data final'}
                        required={true}
                        format="DD/MM/YYYY"
                        placeholder="DD/MM/YYYY"
                        onChange={(date: any) => {
                          handleChangeDataFim(date.toISOString());
                          input.onChange(date);
                        }}
                        validate={undefined}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
      </DialogContent>
      <DialogActions>
        <Box padding={2} display="flex" gridGap={4}>
          <Button variant="outlined" color="primary" onClick={closeModal}>
            Fechar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            fullWidth
            disabled={datas.dataFim === '' || datas.dataInicio === '' || !selectedBusinessUnits.length}
          >
            Gerar Relatório
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default RelatorioMovimentacaoPage;
