import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { useHistory } from 'react-router';
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import { setErrorFeedback, setSuccessFeedback } from '../../../../features/feedback/feedbackSlice';
import { generateContasPagarReport } from './contasPagarPorDataVencimentoPage.helpers';
import { useUnidadeNegocioGuard } from '../../../common/hooks/useUnidadeNegocioGuard';
import {
  selectUnidadeSelecionada,
  selectUnidadesDeNegocioAssociadas,
} from '../../../../features/usuario-logado/usuarioLogadoSlice';
import { GridItemModalProps } from '../../../common/components/cardsGrid/cardsModalGrid';
import { Field, Form } from 'react-final-form';
import QCXFinalDatePickerField from '../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXTextField from '../../../../shared-components/text-field/QCXTextField';
import QCXSelectContaBancariaAutocompleteField from '../../../../shared-components/select-conta-bancaria/QCXSelectContaBancariaAutocompleteField';
import QCXSelectFornecedorAutocomplete from '../../../../shared-components/select-fornecedor-favorecido-autocomplete/QCXSelectFornecedorAutocomplete';
import { useListClientes } from '../../../common/hooks/useListClientes';
import MultiSelectStyled, { SimpleMultiSelectOption } from '../../../common/components/input/multiSelectStyled';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      minWidth: '50vw',
    },
  },
  // Add any styles you need
});

const ContasPagarPorDataVencimentoPage = ({ title, isOpen, closeModal }: GridItemModalProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { keycloak } = useKeycloak();
  const { token } = keycloak;

  keycloak.updateToken(300);

  const selectedUnit = useSelector(selectUnidadeSelecionada);
  const tenantList = useSelector(selectUnidadesDeNegocioAssociadas);
  const [clientes] = useListClientes();

  const [selectedClients, setSelectedClients] = useState<SimpleMultiSelectOption[]>([]);
  const [selectedFornecedores, setSelectedFornecedores] = useState<string>('');
  const [selectedTenants, setSelectedTenants] = useState<SimpleMultiSelectOption[]>([]);
  const [businessError, setBusinessError] = useState(false);
  const [origem, setOrigem] = useState('');
  const [bancoId, setBancoId] = useState<number | null>(null);
  const [datas, setDatas] = useState({
    dataInicio: '',
    dataFim: '',
  });

  useUnidadeNegocioGuard();

  const handleTenantsChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: SimpleMultiSelectOption[] | null
  ) => {
    setSelectedTenants(value || []);
  };

  const handleClientsChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: SimpleMultiSelectOption[] | null
  ) => {
    setSelectedClients(value || []);
  };

  const handleOrigemChange = (event: SelectChangeEvent<string>) => {
    setOrigem(event.target.value as string);
  };

  const handleChangeBanco = (_event: any, option: any) => {
    setBancoId(option?.id || null);
  };

  const handleChangeDataInicio = (isoDate: string) => {
    const value = isoDate.split('T')[0];
    setDatas((prevDatas) => ({ ...prevDatas, dataInicio: value }));
  };

  const handleChangeDataFim = (isoDate: string) => {
    const value = isoDate.split('T')[0];
    setDatas((prevDatas) => ({ ...prevDatas, dataFim: value }));
  };

  const validarDatas = (datas: { dataInicio: string; dataFim: string }): boolean => {
    if (!datas.dataInicio || !datas.dataFim) {
      return false;
    }
    const dataInicio = new Date(datas.dataInicio);
    const dataFim = new Date(datas.dataFim);
    return dataInicio <= dataFim;
  };

  const handleSubmit = async () => {
    keycloak.updateToken(300);

    if (!validarDatas(datas)) {
      dispatch(
        setErrorFeedback({
          message: 'Preencha as datas de início e fim corretamente.',
        })
      );
      return;
    }

    if (selectedTenants.length === 0) {
      setBusinessError(true);
      dispatch(setErrorFeedback({ message: 'Selecione pelo menos uma Unidade de Negócio.' }));
      return;
    } else {
      setBusinessError(false);
    }

    if (token && selectedUnit) {
      try {
        const tenantIds = selectedTenants.map((tenant) => tenant.id.toString());
        const clientIds = selectedClients.map((client) => client.id.toString());

        const error = await generateContasPagarReport(
          token,
          selectedUnit,
          tenantIds,
          clientIds,
          selectedFornecedores,
          datas.dataInicio,
          datas.dataFim,
          origem,
          bancoId
        );
        if (error != null) {
          dispatch(
            setErrorFeedback({
              message: error ? error : 'Erro ao gerar o relatório.',
            })
          );
        } else {
          dispatch(
            setSuccessFeedback({
              message: 'Relatório gerado com sucesso!',
            })
          );
        }
      } catch (error) {
        dispatch(
          setErrorFeedback({
            message: 'Erro inesperado ao gerar o relatório.',
          })
        );
      }
    }
    keycloak.updateToken(300);
    history.push('/financeiro/relatorios');
  };

  return (
    <Dialog className={classes.root} open={isOpen} onClose={closeModal}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Form onSubmit={() => {}}>
          {() => (
            <form>
              <Grid container spacing={2}>
                {/* Unidade de Negócio */}
                <Grid item xs={12}>
                  <FormControl fullWidth error={businessError}>
                    <MultiSelectStyled
                      disablePortal={false}
                      controlledValues={selectedTenants}
                      options={tenantList.map((tenant: { id: any; pessoa: { nomeResumido: any } }) => ({
                        id: tenant.id.toString(),
                        value: tenant.pessoa.nomeResumido,
                      }))}
                      onChangeAction={handleTenantsChange}
                      label={'Unidade de Negócio'}
                    ></MultiSelectStyled>
                    {selectedTenants.length === 0 && (
                      <Typography variant="body2" color="error">
                        Este campo é obrigatório
                      </Typography>
                    )}
                  </FormControl>
                </Grid>

                {/* Clientes multi-select */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <MultiSelectStyled
                      disablePortal={false}
                      options={clientes.map((client) => ({
                        id: client.id ?? '',
                        value: `(${client.pessoa!.cnpj}) ${client.pessoa!.nome}`,
                      }))}
                      onChangeAction={handleClientsChange}
                      label={'Clientes'}
                    />
                  </FormControl>
                </Grid>

                {/* Fornecedor select */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <QCXSelectFornecedorAutocomplete
                      id="select-field-fornecedor"
                      key="select-field-fornecedor"
                      name="fornecedorId"
                      label="Fornecedor"
                      initialValues={selectedFornecedores}
                      disabled={false}
                      filterPredicate={() => false}
                      onChange={(event: React.ChangeEvent<{}>, newValue: any) => {
                        setSelectedFornecedores('');
                        if (newValue) {
                          setSelectedFornecedores(newValue?.id);
                        }
                      }}
                    />
                  </FormControl>
                </Grid>

                {/* Origem select */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <QCXTextField
                      select
                      label="Origem"
                      value={origem}
                      onChange={handleOrigemChange}
                      fullWidth
                      maxLength={255}
                      visible={true}
                      helperText=""
                      meta={{}}
                      disabled={false}
                    >
                      <MenuItem value="">Todos</MenuItem>
                      <MenuItem value="OPERACIONAL">OPERACIONAL</MenuItem>
                      <MenuItem value="ADMINISTRATIVA">ADMINISTRATIVA</MenuItem>
                    </QCXTextField>
                  </FormControl>
                </Grid>

                {/* Conta Bancária */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <QCXSelectContaBancariaAutocompleteField
                      id="autocomplete-select-conta-bancaria-field"
                      key="autocomplete-select-conta-bancaria-field"
                      name="contaBancaria"
                      label="Conta Bancária"
                      initialValues={undefined}
                      filtraControlaSaldo={true}
                      onChange={handleChangeBanco}
                    />
                  </FormControl>
                </Grid>

                {/* Data de Início */}
                <Grid item xs={6}>
                  <Field
                    name="startDate"
                    render={({ input }) => (
                      <QCXFinalDatePickerField
                        id="contas-pagar-vencimento-start-date"
                        name={input.name}
                        label={'Data de Início'}
                        required={true}
                        format="DD/MM/YYYY"
                        placeholder="DD/MM/YYYY"
                        onChange={(date: any) => {
                          handleChangeDataInicio(date.toISOString());
                          input.onChange(date);
                        }}
                        validate={undefined}
                      />
                    )}
                  />
                </Grid>

                {/* Data Final */}
                <Grid item xs={6}>
                  <Field
                    name="endDate"
                    render={({ input }) => (
                      <QCXFinalDatePickerField
                        id="contas-pagar-vencimento-end-date"
                        name={input.name}
                        label={'Data Final'}
                        required={true}
                        format="DD/MM/YYYY"
                        placeholder="DD/MM/YYYY"
                        onChange={(date: any) => {
                          handleChangeDataFim(date.toISOString());
                          input.onChange(date);
                        }}
                        validate={undefined}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
      </DialogContent>
      <DialogActions>
        <Box padding={2} display="flex" gridGap={4}>
          <Button variant="outlined" color="primary" onClick={closeModal}>
            Fechar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            fullWidth
            disabled={
              datas.dataFim === '' ||
              datas.dataInicio === '' ||
              selectedTenants.length === 0
            }
          >
            Gerar Relatório
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ContasPagarPorDataVencimentoPage;