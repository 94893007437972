/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid, validate } from 'uuid';
import _ from 'lodash';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXFinalFormDataReplicator from '../form-data-replicator/QCXFinalFormDataReplicator';
import QCXTabsManager from '../tabs/QCXTabsManager';
import QCXRelacaoFornecedorFieldGroup from '../relacao-fornecedor/QCXRelacaoFornecedorFieldGroup';
import { loading, setModel, resetStatus } from '../../features/declaracao-importacao/declaracaoImportacaoSlice';
import QCXSelectCoberturaCambialAutocomplete from '../../shared-components/select-cobertura-cambial/QCXSelectCoberturaCambialAutocomplete';
import QCXSelectMotivoImportacaoSemCoberturaCambialAutocomplete from '../../shared-components/select-motivo-importacao-sem-cobertura-cambial/QCXSelectMotivoImportacaoSemCoberturaCambialAutocomplete';
import QCXSelectVinculoCompradorVendedorAutocomplete from '../../shared-components/select-vinculo-comprador-vendedor/QCXSelectVinculoCompradorVendedorAutocomplete';
import QCXSelectCFOPOperacoesFiscaisAutocomplete from '../../shared-components/final-autocomplete-select-cfop-operacoes-fiscais-field/QCXSelectCFOPOperacoesFiscaisAutocomplete';
import QCXFinalSelectAplicacaoMercadoriaAutocomplete from '../../shared-components/select-aplicacao-mercadoria/QCXFinalSelectAplicacaoMercadoriaAutocomplete';
import QCXSelectCondicaoVendaAutocomplete from '../../shared-components/select-condicao-venda/QCXSelectCondicaoVendaAutocomplete';
import QCXSelectIncotermCondicaoVendaAutocomplete from '../../shared-components/final-select-incoterm-condicao-venda-field/QCXSelectIncotermCondicaoVendaAutocomplete';
import QCXSelectMetodoValoracaoAutocomplete from '../../shared-components/select-metodo-valoracao/QCXSelectMetodoValoracaoAutocomplete';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXSelectInstituicaoFinanciadoraAutocomplete from '../../shared-components/select-instituicao-financiadora/QCXSelectInstituicaoFinanciadoraAutocomplete';
import QCXFormTitle from '../form-title/QCXFormTitle';
import {
  configureGeneralRegimeTributacaoMatcherWith,
  configureSpecificRegimeTributacaoSuspensaoMatcher,
  REGIME_TRIBUTACAO_IMUNIDADE_CODE,
  REGIME_TRIBUTACAO_NAO_INCIDENCIA_CODE,
} from '../../shared-components/select-regime-tributacao/regimeTributacaoUtils';
import { setErrorFeedback, setSuccessFeedback } from '../../features/feedback/feedbackSlice';
import QCXIICommonFormGroup from '../../shared-components/ii-form-group/QCXIICommonFormGroup';
import QCXIPICommonFormGroup from '../../shared-components/ipi-form-group/QCXIPICommonFormGroup';
import QCXPisCofinsCommonFormGroup from '../../shared-components/pis-cofins-form-group/QCXPisCofinsCommonFormGroup';
import QCXIcmsCommonFormGroup from '../../shared-components/icms-form-group/QCXIcmsCommonFormGroup';
import {
  selectRegimesTributacao,
  fetchAllAsync as fetchAllRegimeTributacaoAsync,
} from '../../features/regime-tributacao/regimeTributacaoSlice';
import useOperationMiddleware from '../../utils/hooks/operation/middleware/useOperationMiddleware';
import QCXConfirmDialog from '../../shared-components/dialog/QCXConfirmDialog';

import { normalizeNumeral, normalizeYear } from '../../utils/general/general-utils';
import {
  TIPO_ACRESCIMO,
  TIPO_DEDUCAO,
} from '../../shared-components/select-tipo-acrescimo-deducao/tipoAcrescimoDeducaoUtils';

import { parseToBoolean } from '../../utils/general/parse-utils';
import {
  COBERTURA_CAMBIAL_COM_COBERTURA_180_360_DIAS,
  COBERTURA_CAMBIAL_COM_COBERTURA_ACIMA_360_DIAS,
  COBERTURA_CAMBIAL_COM_COBERTURA_ATE_180_DIAS,
  COBERTURA_CAMBIAL_SEM_COBERTURA,
  isCoberturaCambialComCoberturaAcima360Dias,
  isCoberturaCambialSemCobertura,
  isSomeCoberturaCambial,
} from '../../shared-components/select-cobertura-cambial/coberturaCambialUtils';
import {
  configureGeneralTipoDeclaracaoMatcherWith,
  getSuggestiveValues,
  TIPO_DECLARACAO_ADMISSAO_EM_ALC_AREA_DE_LIVRE_COMERCIO,
  TIPO_DECLARACAO_ADMISSAO_EM_DAD_DEPOSITO_ADUANEIRO_DE_DISTRIBUICAO,
  TIPO_DECLARACAO_ADMISSAO_EM_DE_DAF,
  TIPO_DECLARACAO_ADMISSAO_EM_EIZOF_ENTREPOSTO_INTERNACIONAL_DA_ZFM,
  TIPO_DECLARACAO_ADMISSAO_EM_ENTREPOSTO_ADUANEIRO,
  TIPO_DECLARACAO_ADMISSAO_EM_ENTREPOSTO_INDUSTRIAL,
  TIPO_DECLARACAO_ADMISSAO_EM_LOJA_FRANCA,
  TIPO_DECLARACAO_ADMISSAO_NA_ZFM_ZONA_FRANCA_DE_MANAUS,
  TIPO_DECLARACAO_ADMISSAO_TEMPORARIA,
  TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
  TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
  TIPO_DECLARACAO_INTERNACAO_DE_ALC_AREA_DE_LIVRE_COMERCIO,
  TIPO_DECLARACAO_SAIDA_DE_ENTREPOSTO_INDUSTRIAL,
  TIPO_DECLARACAO_DUIMP,
} from '../../shared-components/select-tipo-declaracao/tipoDeclaracaoUtils';

import {
  selectMotivosImportacaoSemCoberturaCambial,
  fetchAllAsync as fetchAllMotivosImportacaoSemCoberturaCambialAsync,
} from '../../features/motivo-importacao-sem-cobertura-cambial/motivoImportacaoSemCoberturaCambialSlice';
import { selectTiposDeclaracao } from '../../features/tipo-declaracao/tipoDeclaracaoSlice';
import { useFornecedorListenersGroup } from '../relacao-fornecedor/relacao-fornecedor-utils';
import useIpiCommonFormGroupListeners from '../../shared-components/ipi-form-group/ipiCommonFormGroupListeners';
import QCXSelectPortoAeroportoAutocomplete from '../../shared-components/select-porto-aeroporto/QCXSelectPortoAeroportoAutocomplete';
import QCXFinalNumericIntegerField from '../../shared-components/final-numeric-integer-field/QCXFinalNumericIntegerField';

import QCXIIFormGroup from '../../shared-components/ii-form-group/QCXIIFormGroup';
import { BRAZILIAN } from '../../utils/hooks/form/field/mask-types';
import QCXIPIFormGroup from '../../shared-components/ipi-form-group/QCXIPIFormGroup';
import QCXPisCofinsFormGroup from '../../shared-components/pis-cofins-form-group/QCXPisCofinsFormGroup';
import QCXAntidumpingFormGroup from '../../shared-components/antidumping-form-group/QCXAntidumpingFormGroup';
import QCXAFRMMFormGroup from '../../shared-components/afrmm-form-group/QCXAFRMMFormGroup';
import QCXSisComexFormGroup from '../../shared-components/siscomex-form-group/QCXSisComexFormGroup';
import QCXIcmsFormGroup from '../../shared-components/icms-form-group/QCXIcmsFormGroup';
import QCXFinalAtributoNveBondManagerManyAdicoes from './QCXFinalAtributoNveBondManagerManyAdicoes';

const useStyles = makeStyles((theme) => ({
  gridField: {
    paddingBottom: '16px',
  },
  checkboxGridField: {
    paddingBottom: '8px',
  },
  leftSiblingGridField: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '16px',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '8px',
    },
  },
  rightSiblingGridField: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '16px',
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: '8px',
    },
  },
  sibling: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '8px',
      marginTop: '8px',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '8px',
    },
  },
}));

export default function QCXDeclaracaoImportacaoAuxiliarForm({
  isLoading,
  isCreate,
  isConsult,
  isUpdate,
  parentModel,
  authInfo,
  requiredRoles,
  handleCustomSaveBy,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  controlComponentProps,
  ...restProps
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();

  const regimesTributacao = useSelector(selectRegimesTributacao);
  const tiposDeclaracao = useSelector(selectTiposDeclaracao);
  const motivosImportacaoSemCoberturaCambial = useSelector(selectMotivosImportacaoSemCoberturaCambial);

  const [replicated, setReplicated] = useState(false);

  useEffect(() => {
    dispatch(fetchAllRegimeTributacaoAsync());
    dispatch(fetchAllMotivosImportacaoSemCoberturaCambialAsync());
  }, []);

  const modes = useMemo(
    () => ({
      isConsult,
      isCreate,
      isUpdate,
    }),
    [isConsult, isCreate, isUpdate]
  );

  const adicoes = useMemo(() => parentModel?.atributosAdicionais?.adicoes || [], [parentModel]);

  const tipoDeclaracao = useMemo(() => parentModel?.atributosAdicionais?.tipoDeclaracao, [parentModel]);

  const isSomeTipoDeclaracaoBy = useCallback(
    (codesToCompare) => configureGeneralTipoDeclaracaoMatcherWith(tiposDeclaracao)(tipoDeclaracao?.id, codesToCompare),
    [tiposDeclaracao, tipoDeclaracao]
  );

  const isSuggestibleValues = useMemo(
    () =>
      isSomeTipoDeclaracaoBy([
        TIPO_DECLARACAO_ADMISSAO_EM_ENTREPOSTO_ADUANEIRO,
        TIPO_DECLARACAO_ADMISSAO_EM_EIZOF_ENTREPOSTO_INTERNACIONAL_DA_ZFM,
        TIPO_DECLARACAO_ADMISSAO_EM_ENTREPOSTO_INDUSTRIAL,
        TIPO_DECLARACAO_ADMISSAO_TEMPORARIA,
        TIPO_DECLARACAO_ADMISSAO_NA_ZFM_ZONA_FRANCA_DE_MANAUS,
        TIPO_DECLARACAO_ADMISSAO_EM_LOJA_FRANCA,
        TIPO_DECLARACAO_ADMISSAO_EM_ALC_AREA_DE_LIVRE_COMERCIO,
        TIPO_DECLARACAO_ADMISSAO_EM_DAD_DEPOSITO_ADUANEIRO_DE_DISTRIBUICAO,
        TIPO_DECLARACAO_ADMISSAO_EM_DE_DAF,
        TIPO_DECLARACAO_SAIDA_DE_ENTREPOSTO_INDUSTRIAL,
        TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
        TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
        TIPO_DECLARACAO_INTERNACAO_DE_ALC_AREA_DE_LIVRE_COMERCIO,
      ]),
    [isSomeTipoDeclaracaoBy]
  );

  const suggestiveValues = useMemo(
    () =>
      getSuggestiveValues({
        regimesTributacao,
        tipoDeclaracao,
        motivosImportacaoSemCoberturaCambial,
      }),
    [getSuggestiveValues, regimesTributacao, tipoDeclaracao, motivosImportacaoSemCoberturaCambial]
  );

  const initialValues = useMemo(
    () => (isSuggestibleValues ? suggestiveValues : {}),
    [isSuggestibleValues, suggestiveValues]
  );

  const normalizeOld = useCallback(
    (unnormalizedData) => ({
      ...unnormalizedData,
      relacao: unnormalizedData?.relacao,
      exportador: unnormalizedData?.exportador?.id ? unnormalizedData?.exportador : undefined,
      fabricante: unnormalizedData?.fabricante?.id ? unnormalizedData?.fabricante : undefined,
      paisOrigem: unnormalizedData?.paisOrigem?.id ? unnormalizedData?.paisOrigem : undefined,
      operacaoFiscal: unnormalizedData?.operacaoFiscal?.id ? unnormalizedData?.operacaoFiscal : undefined,
      coberturaCambial: unnormalizedData?.coberturaCambial,
      instituicaoFinanciadora: unnormalizedData?.instituicaoFinanciadora?.id
        ? unnormalizedData?.instituicaoFinanciadora
        : undefined,
      motivoImportacaoSemCoberturaCambial: unnormalizedData?.motivoImportacaoSemCoberturaCambial?.id
        ? unnormalizedData?.motivoImportacaoSemCoberturaCambial
        : undefined,
      condicaoMercadoria: unnormalizedData?.condicaoMercadoria,
      aplicacaoMercadoria: unnormalizedData?.aplicacaoMercadoria,
      incotermCondicaoVenda: unnormalizedData?.incotermCondicaoVenda?.id
        ? unnormalizedData?.incotermCondicaoVenda
        : undefined,
      metodoValoracaoAduaneira: unnormalizedData?.metodoValoracaoAduaneira?.id
        ? unnormalizedData?.metodoValoracaoAduaneira
        : undefined,
      valorFornecedor: normalizeNumeral(unnormalizedData?.valorFornecedor),
      numeroRof: unnormalizedData?.numeroRof,
      destaqueNcm: unnormalizedData?.destaqueNcm,
      quebraAdicaoCfop: parseToBoolean(unnormalizedData?.quebraAdicaoCfop),
      quebraAdicaoNumeroFatura: parseToBoolean(unnormalizedData?.quebraAdicaoNumeroFatura),
      // atributos: unnormalizedData?.atributos || [],
      atributos: (unnormalizedData?.atributos || [])?.map((atributo) =>
        validate(atributo?.id)
          ? {
              ...atributo,
              nve: atributo?.nve?.id ? atributo?.nve : null,
              atributo: atributo?.atributo?.id ? atributo?.atributo : null,
              especificacao: atributo?.especificacao?.id ? atributo?.especificacao : null,
              id: null,
            }
          : {
              ...atributo,
              nve: atributo?.nve?.id ? atributo?.nve : null,
              atributo: atributo?.atributo?.id ? atributo?.atributo : null,
              especificacao: atributo?.especificacao?.id ? atributo?.especificacao : null,
            }
      ),
      acrescimosDeducoes: unnormalizedData?.acrescimosDeducoes || [],
      ii: {
        ...unnormalizedData?.ii,
        regimeTributacao: unnormalizedData?.ii?.regimeTributacao?.id
          ? unnormalizedData?.ii?.regimeTributacao
          : undefined,
        fundamentoLegalRegimeTributacaoIi: unnormalizedData?.ii?.fundamentoLegalRegimeTributacaoIi?.id
          ? unnormalizedData?.ii?.fundamentoLegalRegimeTributacaoIi
          : undefined,
        aliquota: normalizeNumeral(unnormalizedData?.ii?.aliquota),
        percentualReducao: normalizeNumeral(unnormalizedData?.ii?.percentualReducao),
        aliquotaReduzida: normalizeNumeral(unnormalizedData?.ii?.aliquotaReduzida),
      },
      ipi: {
        ...unnormalizedData?.ipi,
        regimeTributacao: unnormalizedData?.ipi?.regimeTributacao?.id
          ? unnormalizedData?.ipi?.regimeTributacao
          : undefined,
        ipiNaoTributavel: parseToBoolean(unnormalizedData?.ipi?.ipiNaoTributavel),
        notaComplementarTipi: unnormalizedData?.ipi?.notaComplementarTipi,
        aliquota: normalizeNumeral(unnormalizedData?.ipi?.aliquota),
        reduzida: normalizeNumeral(unnormalizedData?.ipi?.reduzida),
      },
      pisCofins: {
        ...unnormalizedData?.pisCofins,
        regimeTributacao: unnormalizedData?.pisCofins?.regimeTributacao?.id
          ? unnormalizedData?.pisCofins?.regimeTributacao
          : undefined,
        fundamentoLegalRegimeTributacaoPisCofins: unnormalizedData?.pisCofins?.fundamentoLegalRegimeTributacaoPisCofins
          ?.id
          ? unnormalizedData?.pisCofins?.fundamentoLegalRegimeTributacaoPisCofins
          : undefined,
        aliquotaReduzidaPisPasep: normalizeNumeral(unnormalizedData?.pisCofins?.aliquotaReduzidaPisPasep),
        aliquotaReduzidaCofins: normalizeNumeral(unnormalizedData?.pisCofins?.aliquotaReduzidaCofins),
      },
      icms: {
        ...unnormalizedData?.icms,
        regimeTributacao: unnormalizedData?.icms?.regimeTributacao?.id
          ? unnormalizedData?.icms?.regimeTributacao
          : undefined,
        dispositivoLegal: unnormalizedData?.icms?.dispositivoLegal?.id
          ? unnormalizedData?.icms?.dispositivoLegal
          : undefined,
        aliquota: normalizeNumeral(unnormalizedData?.icms?.aliquota),
        aliquotaOriginal: normalizeNumeral(unnormalizedData?.icms?.aliquotaOriginal),
        aliquotaFecp: normalizeNumeral(unnormalizedData?.icms?.aliquotaFecp),
        tipoReducao: unnormalizedData?.icms?.tipoReducao,
        percentualReducao: normalizeNumeral(unnormalizedData?.icms?.percentualReducao),
      },
    }),
    []
  );

  const normalize = useCallback((unnormalizedData) => {
    const mapAcrecimoDeducao = (acrecimoDeducao) => {
      const isUUid = validate(acrecimoDeducao?.id);

      if (isUUid) {
        const obj = {
          ...acrecimoDeducao,
          id: null,
          valorMoeda: normalizeNumeral(acrecimoDeducao?.valorMoeda),
          valorReal: normalizeNumeral(acrecimoDeducao?.valorReal),
          moeda: acrecimoDeducao?.moeda?.id ? acrecimoDeducao?.moeda : null,
          acrescimoValoracaoAduaneira:
            acrecimoDeducao?.acrescimoValoracaoAduaneira?.id && acrecimoDeducao?.tipo === TIPO_ACRESCIMO
              ? acrecimoDeducao?.acrescimoValoracaoAduaneira
              : null,
          deducaoValoracaoAduaneira:
            acrecimoDeducao?.acrescimoValoracaoAduaneira?.id && acrecimoDeducao?.tipo === TIPO_DEDUCAO
              ? acrecimoDeducao?.acrescimoValoracaoAduaneira
              : null,
        };
        return obj;
      }

      const obj = {
        ...acrecimoDeducao,
        valorMoeda: normalizeNumeral(acrecimoDeducao?.valorMoeda),
        valorReal: normalizeNumeral(acrecimoDeducao?.valorReal),
        moeda: acrecimoDeducao?.moeda?.id ? acrecimoDeducao?.moeda : null,
        acrescimoValoracaoAduaneira:
          acrecimoDeducao?.acrescimoValoracaoAduaneira?.id && acrecimoDeducao?.tipo === TIPO_ACRESCIMO
            ? acrecimoDeducao?.acrescimoValoracaoAduaneira
            : null,
        deducaoValoracaoAduaneira:
          acrecimoDeducao?.acrescimoValoracaoAduaneira?.id && acrecimoDeducao?.tipo === TIPO_DEDUCAO
            ? acrecimoDeducao?.acrescimoValoracaoAduaneira
            : null,
      };

      return obj;
    };
    const normalizedAcrescimosDeducoes = (unnormalizedData?.acrescimosDeducoes || [])?.map(mapAcrecimoDeducao);

    return {
      ...unnormalizedData,
      relacao: unnormalizedData?.relacao,
      coberturaCambial: unnormalizedData?.coberturaCambial,
      condicaoMercadoria: unnormalizedData?.condicaoMercadoria,
      aplicacaoMercadoria: unnormalizedData?.aplicacaoMercadoria,
      numeroRof: unnormalizedData?.numeroRof,
      destaqueNcm: unnormalizedData?.destaqueNcm,
      quebraAdicaoCfop: parseToBoolean(unnormalizedData?.quebraAdicaoCfop),
      quebraAdicaoNumeroFatura: parseToBoolean(unnormalizedData?.quebraAdicaoNumeroFatura),
      exportador: unnormalizedData?.exportador?.id ? unnormalizedData?.exportador : undefined,
      fabricante: unnormalizedData?.fabricante?.id ? unnormalizedData?.fabricante : undefined,
      paisOrigem: unnormalizedData?.paisOrigem?.id ? unnormalizedData?.paisOrigem : undefined,
      instituicaoFinanciadora: unnormalizedData?.instituicaoFinanciadora?.id
        ? unnormalizedData?.instituicaoFinanciadora
        : undefined,
      motivoImportacaoSemCoberturaCambial: unnormalizedData?.motivoImportacaoSemCoberturaCambial?.id
        ? unnormalizedData?.motivoImportacaoSemCoberturaCambial
        : undefined,
      moeda: unnormalizedData?.moeda?.id ? unnormalizedData?.moeda : null,
      moedaDespesa: unnormalizedData?.moedaDespesa?.id ? unnormalizedData?.moedaDespesa : null,
      ncm: unnormalizedData?.ncm?.id ? unnormalizedData?.ncm : null,
      naladiNcca: unnormalizedData?.naladiNcca?.id ? unnormalizedData?.naladiNcca : null,
      naladiSh: unnormalizedData?.naladiSh?.id ? unnormalizedData?.naladiSh : null,
      operacaoFiscal: unnormalizedData?.operacaoFiscal?.id ? unnormalizedData?.operacaoFiscal : null,
      atributos: (unnormalizedData?.atributos || [])?.map((atributo) =>
        validate(atributo?.id)
          ? {
              ...atributo,
              nve: atributo?.nve?.id ? atributo?.nve : null,
              atributo: atributo?.atributo?.id ? atributo?.atributo : null,
              especificacao: atributo?.especificacao?.id ? atributo?.especificacao : null,
              id: null,
            }
          : {
              ...atributo,
              nve: atributo?.nve?.id ? atributo?.nve : null,
              atributo: atributo?.atributo?.id ? atributo?.atributo : null,
              especificacao: atributo?.especificacao?.id ? atributo?.especificacao : null,
            }
      ),
      declaracaoExportacoes: (unnormalizedData?.declaracaoExportacoes || [])?.map((de) =>
        validate(de?.id)
          ? {
              ...de,
              id: null,
              qtdUnidadeEstatistica: normalizeNumeral(de?.qtdUnidadeEstatistica),
            }
          : {
              ...de,
              qtdUnidadeEstatistica: normalizeNumeral(de?.qtdUnidadeEstatistica),
            }
      ),
      valorFornecedor: normalizeNumeral(unnormalizedData?.valorFornecedor),
      pesoLiquido: normalizeNumeral(unnormalizedData?.pesoLiquido),
      pesoBruto: normalizeNumeral(unnormalizedData?.pesoBruto),
      quantidadeEstatistica: normalizeNumeral(unnormalizedData?.quantidadeEstatistica),
      valorMleReal: normalizeNumeral(unnormalizedData?.valorMleReal),
      valorMleMoeda: normalizeNumeral(unnormalizedData?.valorMleMoeda),
      valorDespesaMoeda: normalizeNumeral(unnormalizedData?.valorDespesaMoeda),
      valorDespesaReal: normalizeNumeral(unnormalizedData?.valorDespesaReal),
      valorCifReal: normalizeNumeral(unnormalizedData?.valorCifReal),
      valorMcvMoeda: normalizeNumeral(unnormalizedData?.valorMcvMoeda),
      valorMcvReal: normalizeNumeral(unnormalizedData?.valorMcvReal),
      valorFreteTerritorioNacionalMoeda: normalizeNumeral(unnormalizedData?.valorFreteTerritorioNacionalMoeda),
      valorFreteTerritorioNacionalReal: normalizeNumeral(unnormalizedData?.valorFreteTerritorioNacionalReal),
      valorSeguroMoeda: normalizeNumeral(unnormalizedData?.valorSeguroMoeda),
      valorSeguroReal: normalizeNumeral(unnormalizedData?.valorSeguroReal),
      valorFreteMoeda: normalizeNumeral(unnormalizedData?.valorFreteMoeda),
      valorFreteReal: normalizeNumeral(unnormalizedData?.valorFreteReal),
      acrescimosDeducoes: normalizedAcrescimosDeducoes,
      incotermCondicaoVenda: unnormalizedData?.incotermCondicaoVenda?.id
        ? unnormalizedData?.incotermCondicaoVenda
        : null,
      metodoValoracaoAduaneira: unnormalizedData?.metodoValoracaoAduaneira?.id
        ? unnormalizedData?.metodoValoracaoAduaneira
        : null,
      portoAeroporto: unnormalizedData?.portoAeroporto?.id ? unnormalizedData?.portoAeroporto : null,
      ii: {
        ...unnormalizedData?.ii,
        fundamentoLegalRegimeTributacaoIi: unnormalizedData?.ii?.fundamentoLegalRegimeTributacaoIi?.id
          ? unnormalizedData?.ii?.fundamentoLegalRegimeTributacaoIi
          : null,
        tipoAtoLegalExTarifario: unnormalizedData?.ii?.tipoAtoLegalExTarifario?.id
          ? unnormalizedData?.ii?.tipoAtoLegalExTarifario
          : null,
        tipoAtoLegalAcordoTarifario: unnormalizedData?.ii?.tipoAtoLegalAcordoTarifario?.id
          ? unnormalizedData?.ii?.tipoAtoLegalAcordoTarifario
          : null,
        regimeTributacao: unnormalizedData?.ii?.regimeTributacao?.id ? unnormalizedData?.ii?.regimeTributacao : null,
        orgaoEmissorAtoLegalExTarifario: unnormalizedData?.ii?.orgaoEmissorAtoLegalExTarifario?.id
          ? unnormalizedData?.ii?.orgaoEmissorAtoLegalExTarifario
          : null,
        orgaoEmissorAtoLegalAcordoTarifario: unnormalizedData?.ii?.orgaoEmissorAtoLegalAcordoTarifario?.id
          ? unnormalizedData?.ii?.orgaoEmissorAtoLegalAcordoTarifario
          : undefined,
        ano: normalizeYear(unnormalizedData?.ii?.ano),
        anoExTarifario: normalizeYear(unnormalizedData?.ii?.anoExTarifario),
        anoAcordoTarifario: normalizeYear(unnormalizedData?.ii?.anoAcordoTarifario),
        baseCalculoReal: normalizeNumeral(unnormalizedData?.ii?.baseCalculoReal),
        aliquota: normalizeNumeral(unnormalizedData?.ii?.aliquota),
        aliquotaAcordo: normalizeNumeral(unnormalizedData?.ii?.aliquotaAcordo),
        percentualReducao: normalizeNumeral(unnormalizedData?.ii?.percentualReducao),
        aliquotaReduzida: normalizeNumeral(unnormalizedData?.ii?.aliquotaReduzida),
        coeficienteReducao: normalizeNumeral(unnormalizedData?.ii?.coeficienteReducao),
        valorDolar: normalizeNumeral(unnormalizedData?.ii?.valorDolar),
        valorReal: normalizeNumeral(unnormalizedData?.ii?.valorReal),
        valorDevidoReal: normalizeNumeral(unnormalizedData?.ii?.valorDevidoReal),
        valorRecolherReal: normalizeNumeral(unnormalizedData?.ii?.valorRecolherReal),
        recolherPermanencia: normalizeNumeral(unnormalizedData?.ii?.recolherPermanencia),
        ...(unnormalizedData?.ii?.acordoAladi && {
          acordoAladi: { id: unnormalizedData?.ii?.acordoAladi },
        }),
      },
      ipi: {
        ...unnormalizedData?.ipi,
        tipoAtoLegalFundamentoLegal: unnormalizedData?.ipi?.tipoAtoLegalFundamentoLegal?.id
          ? unnormalizedData?.ipi?.tipoAtoLegalFundamentoLegal
          : null,
        orgaoEmissorFundamentoLegal: unnormalizedData?.ipi?.orgaoEmissorFundamentoLegal?.id
          ? unnormalizedData?.ipi?.orgaoEmissorFundamentoLegal
          : null,
        numeroAtoFundamentoLegal: unnormalizedData?.ipi?.numeroAtoFundamentoLegal,
        anoFundamentoLegal: normalizeYear(unnormalizedData?.ipi?.anoFundamentoLegal),
        numeroExFundamentoLegal: unnormalizedData?.ipi?.numeroExFundamentoLegal,
        orgaoEmissorAtoLegal: unnormalizedData?.ipi?.orgaoEmissorAtoLegal?.id
          ? unnormalizedData?.ipi?.orgaoEmissorAtoLegal
          : null,
        regimeTributacao: unnormalizedData?.ipi?.regimeTributacao?.id ? unnormalizedData?.ipi?.regimeTributacao : null,
        tipoRecipiente: unnormalizedData?.ipi?.tipoRecipiente?.id ? unnormalizedData?.ipi?.tipoRecipiente : null,
        unidadeDeMedida: unnormalizedData?.ipi?.unidadeDeMedida?.id ? unnormalizedData?.ipi?.unidadeDeMedida : null,
        tipoAtoLegalExTarifario: unnormalizedData?.ipi?.tipoAtoLegalExTarifario?.id
          ? unnormalizedData?.ipi?.tipoAtoLegalExTarifario
          : null,

        baseCalculoReal: normalizeNumeral(unnormalizedData?.ipi?.baseCalculoReal),
        aliquota: normalizeNumeral(unnormalizedData?.ipi?.aliquota),
        aliquotaReduzida: normalizeNumeral(unnormalizedData?.ipi?.aliquotaReduzida),
        valorUnidadeMedidaReal: normalizeNumeral(unnormalizedData?.ipi?.valorUnidadeMedidaReal),
        valorRecolherReal: normalizeNumeral(unnormalizedData?.ipi?.valorRecolherReal),
        recolherPermanencia: normalizeNumeral(unnormalizedData?.ipi?.recolherPermanencia),
        valorDevidoReal: normalizeNumeral(unnormalizedData?.ipi?.valorDevidoReal),
        quantidade: normalizeNumeral(unnormalizedData?.ipi?.quantidade),
        capacidade: normalizeNumeral(unnormalizedData?.ipi?.capacidade),
        anoExTarifario: normalizeYear(unnormalizedData?.ipi?.anoExTarifario),
        ipiNaoTributavel: parseToBoolean(unnormalizedData?.ipi?.ipiNaoTributavel),
        notaComplementarTipi: unnormalizedData?.ipi?.notaComplementarTipi,
        reduzida: normalizeNumeral(unnormalizedData?.ipi?.reduzida),
      },
      multaLi: {
        ...unnormalizedData?.multaLi,
        valorDevidoReal: normalizeNumeral(unnormalizedData?.multaLi?.valorDevidoReal),
        valorRecolherReal: normalizeNumeral(unnormalizedData?.multaLi?.valorRecolherReal),
      },
      pisCofins: {
        ...unnormalizedData?.pisCofins,
        unidadeDeMedidaPisPasep: unnormalizedData?.pisCofins?.unidadeDeMedidaPisPasep?.id
          ? unnormalizedData?.pisCofins?.unidadeDeMedidaPisPasep
          : null,
        unidadeDeMedidaCofins: unnormalizedData?.pisCofins?.unidadeDeMedidaCofins?.id
          ? unnormalizedData?.pisCofins?.unidadeDeMedidaCofins
          : null,
        regimeTributacao: unnormalizedData?.pisCofins?.regimeTributacao?.id
          ? unnormalizedData?.pisCofins?.regimeTributacao
          : null,
        fundamentoLegalRegimeTributacaoPisCofins: unnormalizedData?.pisCofins?.fundamentoLegalRegimeTributacaoPisCofins
          ?.id
          ? unnormalizedData?.pisCofins?.fundamentoLegalRegimeTributacaoPisCofins
          : null,
        fundamentoLegal: unnormalizedData?.pisCofins?.fundamentoLegal?.id
          ? unnormalizedData?.pisCofins?.fundamentoLegal
          : null,
        baseCalculoReal: normalizeNumeral(unnormalizedData?.pisCofins?.baseCalculoReal),
        percentualReducao: normalizeNumeral(unnormalizedData?.pisCofins?.percentualReducao),
        percentualReducaoBaseCalculo: normalizeNumeral(unnormalizedData?.pisCofins?.percentualReducaoBaseCalculo),
        aliquotaPisPasep: normalizeNumeral(unnormalizedData?.pisCofins?.aliquotaPisPasep),
        aliquotaReduzidaPisPasep: normalizeNumeral(unnormalizedData?.pisCofins?.aliquotaReduzidaPisPasep),
        valorRecolherPisPasepReal: normalizeNumeral(unnormalizedData?.pisCofins?.valorRecolherPisPasepReal),
        valorDevidoCofinsReal: normalizeNumeral(unnormalizedData?.pisCofins?.valorDevidoCofinsReal),
        valorDevidoPisPasepReal: normalizeNumeral(unnormalizedData?.pisCofins?.valorDevidoPisPasepReal),
        recolherPermanenciaPisPasep: normalizeNumeral(unnormalizedData?.pisCofins?.recolherPermanenciaPisPasep),
        recolherPermanenciaCofins: normalizeNumeral(unnormalizedData?.pisCofins?.recolherPermanenciaCofins),
        quantidadePisPasep: normalizeNumeral(unnormalizedData?.pisCofins?.quantidadePisPasep),
        aliquotaCofins: normalizeNumeral(unnormalizedData?.pisCofins?.aliquotaCofins),
        aliquotaReduzidaCofins: normalizeNumeral(unnormalizedData?.pisCofins?.aliquotaReduzidaCofins),
        valorRecolherCofinsReal: normalizeNumeral(unnormalizedData?.pisCofins?.valorRecolherCofinsReal),
        quantidadeCofins: normalizeNumeral(unnormalizedData?.pisCofins?.quantidadeCofins),
      },
      taxaSiscomex: {
        ...unnormalizedData?.taxaSiscomex,
        valorReal: normalizeNumeral(unnormalizedData?.taxaSiscomex?.valorReal),
        valorDolar: normalizeNumeral(unnormalizedData?.taxaSiscomex?.valorDolar),
        valorMoeda: normalizeNumeral(unnormalizedData?.taxaSiscomex?.valorMoeda),
      },
      icms: {
        ...unnormalizedData?.icms,
        regimeTributacao: unnormalizedData?.icms?.regimeTributacao?.id
          ? unnormalizedData?.icms?.regimeTributacao
          : null,
        dispositivoLegal: unnormalizedData?.icms?.dispositivoLegal?.id
          ? unnormalizedData?.icms?.dispositivoLegal
          : null,
        baseCalculoReal: normalizeNumeral(unnormalizedData?.icms?.baseCalculoReal),
        aliquota: normalizeNumeral(unnormalizedData?.icms?.aliquota),
        aliquotaAntecipadaPercentual: normalizeNumeral(unnormalizedData?.icms?.aliquotaAntecipadaPercentual),
        aliquotaOriginal: normalizeNumeral(unnormalizedData?.icms?.aliquotaOriginal),
        aliquotaFecp: normalizeNumeral(unnormalizedData?.icms?.aliquotaFecp),
        percentualReducao: normalizeNumeral(unnormalizedData?.icms?.percentualReducao),
        valorRecolherReal: normalizeNumeral(unnormalizedData?.icms?.valorRecolherReal),
        recolherPermanencia: normalizeNumeral(unnormalizedData?.icms?.recolherPermanencia),
        valorDevidoReal: normalizeNumeral(unnormalizedData?.icms?.valorDevidoReal),
        percentualBeneficio: normalizeNumeral(unnormalizedData?.icms?.percentualBeneficio),
        tipoReducao: unnormalizedData?.icms?.tipoReducao,
      },
      antidumping: {
        ...unnormalizedData?.antidumping,
        orgaoEmissorAtoLegal: unnormalizedData?.antidumping?.orgaoEmissorAtoLegal?.id
          ? unnormalizedData?.antidumping?.orgaoEmissorAtoLegal
          : null,
        unidadeDeMedida: unnormalizedData?.antidumping?.unidadeDeMedida?.id
          ? unnormalizedData?.antidumping?.unidadeDeMedida
          : null,
        tipoAtoLegal: unnormalizedData?.antidumping?.tipoAtoLegal?.id
          ? unnormalizedData?.antidumping?.tipoAtoLegal
          : null,
        ano: normalizeYear(unnormalizedData?.antidumping?.ano),
        aliquota: normalizeNumeral(unnormalizedData?.antidumping?.aliquota),
        valorReal: normalizeNumeral(unnormalizedData?.antidumping?.valorReal),
        quantidade: normalizeNumeral(unnormalizedData?.antidumping?.quantidade),
        baseCalculoReal: normalizeNumeral(unnormalizedData?.antidumping?.baseCalculoReal),
        valorRecolherReal: normalizeNumeral(unnormalizedData?.antidumping?.valorRecolherReal),
        valorDevidoReal: normalizeNumeral(unnormalizedData?.antidumping?.valorDevidoReal),
      },
      afrmm: {
        ...unnormalizedData?.afrmm,
        valorRecolherReal: normalizeNumeral(unnormalizedData?.afrmm?.valorRecolherReal),
        valorDevidoReal: normalizeNumeral(unnormalizedData?.afrmm?.valorDevidoReal),
      },
      mercadorias: unnormalizedData?.mercadorias || [],
    };
  }, []);

  const [handleReplicateOperation, operationOfReplicate] = useOperationMiddleware(
    async ({ meta, ignorableFields, ...values }) => {
      try {
        dispatch(loading());

        const selectedAdicoesId = [...meta?.selected].map((selectedAdicao) => selectedAdicao.id);

        const selectedAdicoes = adicoes.filter((adicao) => selectedAdicoesId.includes(adicao.id));

        const isLancamentoNves =
          ignorableFields?.nveAdc && Array.isArray(ignorableFields?.nveAdc) && ignorableFields?.nveAdc.length > 0;
        const lancamentosNves = isLancamentoNves ? [...ignorableFields?.nveAdc] : [];

        // replica o objeto values do form
        const replicableData = _.cloneDeep(values);

        const relationshipPaths = [
          'exportador',
          'fabricante',
          'paisOrigem',
          'portoAeroporto',
          'operacaoFiscal',
          'instituicaoFinanciadora',
          'motivoImportacaoSemCoberturaCambial',
          'incotermCondicaoVenda',
          'metodoValoracaoAduaneira',
          'ii.regimeTributacao',
          'ii.fundamentoLegalReducaoIi',
          'ipi.regimeTributacao',
          'pisCofins.regimeTributacao',
          'pisCofins.fundamentoLegalRegimeTributacaoPisCofins',
          'icms.regimeTributacao',
          'icms.dispositivoLegal',
        ];

        const createObjectWithId = (object) => {
          if (!_.has(object, 'id')) {
            return undefined;
          }

          return _.create(
            {},
            {
              id: object.id,
            }
          );
        };

        const updatedAdicoes = selectedAdicoes.map((adicaoBase) => {
          const clonedData = _.cloneDeep(adicaoBase);

          const preNormalizedData = relationshipPaths.reduce((previous, currentPath) => {
            const fullCurrentPath = [currentPath, 'id'].join('.');

            if (!_.has(previous, fullCurrentPath)) {
              return previous;
            }

            const updated = _.updateWith(previous, currentPath, createObjectWithId);

            return _.cloneDeep(updated);
          }, clonedData);

          const isNilValueOrObjectId = (value) => {
            if (_.isArray(value)) {
              return false;
            }

            if (_.isPlainObject(value) && _.has(value, 'id')) {
              return _.isNil(_.get(value, 'id'));
            }

            return _.isNil(value);
          };

          const isIgnorableValue = (value) => {
            if (_.isString(value)) {
              return _.isEmpty(value);
            }

            return isNilValueOrObjectId(value);
          };

          const existingImpostosData = _.pick(preNormalizedData, ['ii', 'ipi', 'pisCofins', 'icms']);

          const existingRestPreNormalizedData = _.omit(preNormalizedData, ['ii', 'ipi', 'pisCofins', 'icms']);

          const validIiNormalizedData = _.omitBy(existingImpostosData?.ii, isIgnorableValue);
          const validIpiNormalizedData = _.omitBy(existingImpostosData?.ipi, isIgnorableValue);
          const validPisCofinsNormalizedData = _.omitBy(existingImpostosData?.pisCofins, isIgnorableValue);
          const validIcmsNormalizedData = _.omitBy(existingImpostosData?.icms, isIgnorableValue);

          const preNormalizedImpostosData = {
            ii: validIiNormalizedData,
            ipi: validIpiNormalizedData,
            pisCofins: validPisCofinsNormalizedData,
            icms: validIcmsNormalizedData,
          };

          const existingImpostosReplicableData = _.pick(replicableData, ['ii', 'ipi', 'pisCofins', 'icms']);

          const existingRestPreNormalizedReplicableData = _.omit(replicableData, ['ii', 'ipi', 'pisCofins', 'icms']);

          const validIiNormalizedReplicableData = _.omitBy(existingImpostosReplicableData?.ii, isIgnorableValue);
          const validIpiNormalizedReplicableData = _.omitBy(existingImpostosReplicableData?.ipi, isIgnorableValue);
          const validPisCofinsNormalizedReplicableData = _.omitBy(
            existingImpostosReplicableData?.pisCofins,
            isIgnorableValue
          );
          const validIcmsNormalizedReplicableData = _.omitBy(existingImpostosReplicableData?.icms, isIgnorableValue);

          const preNormalizedImpostosReplicableData = {
            ii: validIiNormalizedReplicableData,
            ipi: validIpiNormalizedReplicableData,
            pisCofins: validPisCofinsNormalizedReplicableData,
            icms: validIcmsNormalizedReplicableData,
          };

          const validPreNormalizedData = _.omitBy(existingRestPreNormalizedData, isIgnorableValue);
          const validPreNormalizedReplicableData = _.omitBy(existingRestPreNormalizedReplicableData, isIgnorableValue);

          const validExistingData = {
            ...validPreNormalizedData,
            ...preNormalizedImpostosData,
          };

          if (isLancamentoNves) {
            lancamentosNves.forEach((nve) => {
              if (Array.isArray(validExistingData.atributos)) {
                const existingIndex = validExistingData.atributos.findIndex(
                  (atributoExistente) => atributoExistente.atributo.id === nve.atributo.id
                );

                if (existingIndex !== -1) {
                  validExistingData.atributos[existingIndex] = nve;
                } else {
                  validExistingData.atributos.push(nve);
                }
              }
            });
          }

          const validReplicableData = {
            ...validPreNormalizedReplicableData,
            ...preNormalizedImpostosReplicableData,
          };

          const updatedData = _.merge(validExistingData, validReplicableData);

          const normalizedData = normalize(updatedData);

          return normalizedData;
        });

        const mergedAdicoes = _.unionBy(updatedAdicoes, parentModel?.atributosAdicionais?.adicoes, 'id');

        const updatedDeclaracaoImportacao = {
          ...parentModel,
          atributosAdicionais: {
            ...parentModel?.atributosAdicionais,
            adicoes: mergedAdicoes,
          },
        };

        dispatch(setModel(updatedDeclaracaoImportacao));

        const processo = updatedDeclaracaoImportacao?.atributosAdicionais?.processo;

        const updatedAdicoesNumber = selectedAdicoes.reduce((previous, current, index, array) => {
          if (index === 0) {
            return current.item;
          }
          if (array.length > 1 && index === array.length - 1) {
            return `${previous} e ${current.item}`;
          }

          return `${previous}, ${current.item}`;
        }, []);

        const isManyUpdated = selectedAdicoes.length > 1;
        const feedbackMessage = isManyUpdated
          ? t('com.muralis.qcx.mensagem.dadosPreenchidosFormulario', {
              adicao: t('com.muralis.qcx.adicoesNumero', { count: updatedAdicoesNumber }),
              processo,
            })
          : t('com.muralis.qcx.mensagem.dadosPreenchidosFormulario', {
              adicao: t('com.muralis.qcx.adicaoNumero', { count: updatedAdicoesNumber }),
              processo,
            });

        dispatch(
          setSuccessFeedback({
            message: feedbackMessage,
          })
        );

        setReplicated(true);
      } catch (error) {
        dispatch(
          setErrorFeedback({
            message: t('com.muralis.qcx.erro.erroRepassarDadosSelecaoAtual', {
              erro: error?.message ? `: ${error?.message}` : '.',
            }),
          })
        );
      } finally {
        dispatch(resetStatus());
      }
    },
    [adicoes, handleCustomSaveBy]
  );

  const handleReplicateOnSubmitMiddleware = useCallback(
    (data) => {
      const configureOperation = () => ({
        options: {
          title: t('com.muralis.qcx.confirmarOperacaoRepasseDados').toUpperCase(),
          message: t('com.muralis.qcx.mensagem.confirmarOperacaoDadosPreenchidosAdicoesSelecionadas'),
          endMessage: t('com.muralis.qcx.certezaDesejaRepassar'),
        },
      });

      handleReplicateOperation(configureOperation, data);
    },
    [handleReplicateOperation]
  );

  const handleResetFormData = useCallback((form) => {
    form.change('relacao', undefined);
    form.reset({
      relacao: null,
    });
    form.restart({});
  }, []);

  const tableProps = useMemo(
    () => ({
      title: t('com.muralis.qcx.adicao.adicoesRepasse'),
      columns: [
        {
          field: 'nroAdicao',
          headerName: t('com.muralis.qcx.adicao.nroAdicao'),
          headerAlign: 'center',
          align: 'center',
          flex: 120,
          valueGetter: ({ row }) => row?.item || '-',
        },
        {
          field: 'quebraAuxiliar',
          headerName: t('com.muralis.qcx.mercadoria.quebraAdicional'),
          headerAlign: 'center',
          align: 'center',
          flex: 120,
          valueGetter: ({ row }) => row?.mercadorias?.[0]?.quebraAuxiliar,
        },
        {
          field: 'ncm',
          headerName: t('com.muralis.qcx.NCM.label'),
          flex: 260,
          valueGetter: ({ row }) => `${row?.ncm?.code} - ${row?.ncm?.description}` || '-',
        },
        {
          field: 'codigoExportador',
          headerName: 'Código Exp.',
          headerAlign: 'center',
          align: 'center',
          flex: 70,
          valueGetter: ({ row }) => row?.exportador?.id || '-',
        },
        {
          field: 'exportador',
          headerName: t('com.muralis.qcx.exportador.label'),
          headerAlign: 'center',
          align: 'center',
          flex: 160,
          valueGetter: ({ row }) => row?.exportador?.pessoaJuridica?.nomeResumido || '-',
        },
        {
          field: 'codigoFabricante',
          headerName: 'Código Fab.',
          headerAlign: 'center',
          align: 'center',
          flex: 70,
          valueGetter: ({ row }) => row?.fabricante?.id || '-',
        },
        {
          field: 'fabricante',
          headerName: t('com.muralis.qcx.fabricante.label'),
          headerAlign: 'center',
          align: 'center',
          flex: 160,
          valueGetter: ({ row }) => row?.fabricante?.pessoaJuridica?.nomeResumido || '-',
        },
      ],
      data: [...adicoes].sort((a, b) => a.item - b.item),
    }),
    [adicoes]
  );

  const tabManagerProps = useMemo(
    () => ({
      scrollButtons: 'auto',
      variant: 'scrollable',
      tabs: [
        {
          label: t('com.muralis.qcx.detalhes'),
        },
        {
          label: t('com.muralis.qcx.imposto.labelPlural'),
        },
        {
          label: t('com.muralis.qcx.NVE.label'),
        },
      ],
    }),
    []
  );

  const renderRelacaoFornecedorSiblings = useCallback(
    (values) => (
      <Grid item className={classes.sibling} xs={12} sm={12} md={12} lg={12}>
        <QCXSelectVinculoCompradorVendedorAutocomplete
          id="autocomplete-select-vinculo-comprador-vendedor-field"
          key="autocomplete-select-vinculo-comprador-vendedor-field"
          name="vinculacaoCompradorVendedor"
          label={t('com.muralis.qcx.vinculacaoCompradorVendedor')}
          initialValues={values}
          disabled={isConsult}
        />
      </Grid>
    ),
    [classes, isConsult]
  );

  const getRegimeTributacaoIIFrom = useCallback((values) => values?.ii?.regimeTributacao?.id, []);

  const isSomeRegimeTributacaoIIBy = useCallback(
    (values, codesToCompare) =>
      configureGeneralRegimeTributacaoMatcherWith(regimesTributacao)(getRegimeTributacaoIIFrom(values), codesToCompare),
    [getRegimeTributacaoIIFrom, regimesTributacao]
  );

  const isRegimeTributacaoIISuspensaoBy = useCallback(
    (values) => configureSpecificRegimeTributacaoSuspensaoMatcher(regimesTributacao)(getRegimeTributacaoIIFrom(values)),
    [getRegimeTributacaoIIFrom, regimesTributacao]
  );

  const relacaoFornecedorListeners = useFornecedorListenersGroup({
    dadosComumFornecedor: false,
  });

  const handleCoberturaCambialChange = (form) => (value) => {
    if (isCoberturaCambialSemCobertura(value)) {
      form.change('instituicaoFinanciadora', undefined);
      form.change('valorFornecedor', undefined);

      return;
    }

    if (
      isSomeCoberturaCambial(value, [
        COBERTURA_CAMBIAL_COM_COBERTURA_ATE_180_DIAS,
        COBERTURA_CAMBIAL_COM_COBERTURA_180_360_DIAS,
      ])
    ) {
      form.change('instituicaoFinanciadora', undefined);
      form.change('motivoImportacaoSemCoberturaCambial', undefined);
      form.change('valorFornecedor', undefined);
      form.change('numeroRof', undefined);

      return;
    }

    if (isCoberturaCambialComCoberturaAcima360Dias(value)) {
      form.change('motivoImportacaoSemCoberturaCambial', undefined);
    }
  };

  const handleRegimeTributacaoIIIdChange = useCallback(
    (form, values) => (value) => {
      if (!isConsult) {
        if (value && isRegimeTributacaoIISuspensaoBy(values)) {
          form.change('ipi.regimeTributacao.id', value);
        }
      }
    },
    [isConsult, isRegimeTributacaoIISuspensaoBy]
  );

  const ipiListeners = useIpiCommonFormGroupListeners();

  const changeListeners = useMemo(
    () => [
      ...relacaoFornecedorListeners,
      {
        name: 'coberturaCambial',
        fn: handleCoberturaCambialChange,
      },
      {
        name: 'ii.regimeTributacao.id',
        fn: handleRegimeTributacaoIIIdChange,
      },
      ...ipiListeners,
    ],
    [ipiListeners, relacaoFornecedorListeners, handleCoberturaCambialChange, handleRegimeTributacaoIIIdChange]
  );

  const atributosNveProps = useMemo(
    () => ({
      modes,
      classes,
      listProps: {
        rootName: 'atributos',
      },
    }),
    [modes, classes]
  );

  const formatSelection = (adicao) => ({ ...adicao });

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleReplicateOnSubmitMiddleware}
      onChangeListeners={changeListeners}
      controlComponentProps={controlComponentProps}
      disableSubmitOnNext
      {...restProps}
    >
      {({ values, submitSucceeded }) => (
        <>
          <>
            <QCXFinalFormDataReplicator
              isConsult={isConsult}
              tableProps={tableProps}
              onReset={handleResetFormData}
              replicated={replicated}
              handleSetReplicated={setReplicated}
              clearSelection={isConsult}
              formatSelection={formatSelection}
            >
              <Grid
                item
                container
                style={{
                  paddingTop: '24px',
                }}
              >
                <QCXTabsManager {...tabManagerProps}>
                  <>
                    <Grid item container spacing={2}>
                      <QCXRelacaoFornecedorFieldGroup
                        isConsult={isConsult}
                        relacao={values?.relacao}
                        fabricante={values?.fabricante}
                        exportador={values?.exportador}
                        required={false}
                        initialValues={values}
                        renderSiblings={renderRelacaoFornecedorSiblings}
                        authInfo={authInfo}
                      />
                      <Grid item container alignContent="flex-start" xs={12} sm={12} md={6} lg={6}>
                        <Grid item className={classes.gridField} xs={12} sm={12} md={12} lg={12}>
                          <QCXSelectCFOPOperacoesFiscaisAutocomplete
                            id="text-cfop-operacoes-fiscais-field"
                            key="text-cfop-operacoes-fiscais-field"
                            name="operacaoFiscal"
                            label={t('com.muralis.qcx.CFOP.label')}
                            initialValues={values}
                            disabled={isConsult}
                          />
                        </Grid>
                        <Grid item className={classes.gridField} xs={12} sm={12} md={12} lg={12}>
                          <QCXSelectCoberturaCambialAutocomplete
                            id="autocomplete-select-cobertura-cambial-field"
                            key="autocomplete-select-cobertura-cambial-field"
                            name="coberturaCambial"
                            label={t('com.muralis.qcx.coberturaCambial')}
                            initialValues={values}
                            disabled={isConsult}
                          />
                        </Grid>
                        {isCoberturaCambialComCoberturaAcima360Dias(values?.coberturaCambial) && (
                          <Grid item className={classes.gridField} xs={12} sm={12} md={12} lg={12}>
                            <QCXSelectInstituicaoFinanciadoraAutocomplete
                              id="autocomplete-select-instituicao-financiadora-field"
                              key="autocomplete-select-instituicao-financiadora-field"
                              name="instituicaoFinanciadora.id"
                              label={t('com.muralis.qcx.instituicaoFinanceira')}
                              initialValues={values}
                              disabled={isConsult}
                            />
                          </Grid>
                        )}
                        {isCoberturaCambialSemCobertura(values?.coberturaCambial) && (
                          <Grid item className={classes.gridField} xs={12} sm={12} md={12} lg={12}>
                            <QCXSelectMotivoImportacaoSemCoberturaCambialAutocomplete
                              id="autocomplete-select-motivo-importacao-sem-cobertura-cambial-field"
                              key="autocomplete-select-motivo-importacao-sem-cobertura-cambial-field"
                              name="motivoImportacaoSemCoberturaCambial.id"
                              label={t('com.muralis.qcx.motivo')}
                              initialValues={values}
                              disabled={isConsult}
                            />
                          </Grid>
                        )}
                        <Grid item className={classes.rightSiblingGridField} xs={12} sm={12} md={6} lg={6}>
                          <QCXSelectCondicaoVendaAutocomplete
                            id="select-field-local-condicao-mercadoria"
                            key="select-field-local-condicao-mercadoria"
                            name="condicaoMercadoria"
                            label={t('com.muralis.qcx.mercadoria.condicaoMercadoria')}
                            initialValues={values}
                            disabled={isConsult}
                          />
                        </Grid>
                        <Grid item className={classes.leftSiblingGridField} xs={12} sm={12} md={6} lg={6}>
                          <QCXFinalSelectAplicacaoMercadoriaAutocomplete
                            id="select-field-aplicacao-mercadoria"
                            key="select-field-aplicacao-mercadoria"
                            name="aplicacaoMercadoria"
                            label={t('com.muralis.qcx.mercadoria.aplicacaoMercadoria')}
                            initialValues={values}
                            disabled={isConsult}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container alignContent="flex-start" xs={12} sm={12} md={6} lg={6}>
                        <Grid item className={classes.gridField} xs={12} sm={12} md={12} lg={12}>
                          <QCXSelectIncotermCondicaoVendaAutocomplete
                            id="select-field-incoterm"
                            key="select-field-incoterm"
                            name="incotermCondicaoVenda.id"
                            label={t('com.muralis.qcx.incoterm')}
                            initialValues={values}
                            disabled={isConsult}
                          />
                        </Grid>
                        <Grid item className={classes.gridField} xs={12} sm={12} md={12} lg={12}>
                          <QCXSelectMetodoValoracaoAutocomplete
                            id="select-field-metodo-de-valoracao"
                            key="select-field-metodo-de-valoracao"
                            name="metodoValoracaoAduaneira.id"
                            label={t('com.muralis.qcx.metodoValoracao')}
                            initialValues={values}
                            disabled={isConsult}
                          />
                        </Grid>
                        {isCoberturaCambialComCoberturaAcima360Dias(values?.coberturaCambial) && (
                          <Grid
                            item
                            style={{
                              paddingBottom: '16px',
                            }}
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                          >
                            <QCXFinalAlternativeCurrencyField
                              id="currency-valor-fornecedor-field"
                              key="currency-valor-fornecedor-field"
                              name="valorFornecedor"
                              label={t('com.muralis.qcx.valor')}
                              submitSucceeded={submitSucceeded}
                              scale={7}
                              disabled={isConsult}
                            />
                          </Grid>
                        )}
                        {isSomeCoberturaCambial(values?.coberturaCambial, [
                          COBERTURA_CAMBIAL_SEM_COBERTURA,
                          COBERTURA_CAMBIAL_COM_COBERTURA_ACIMA_360_DIAS,
                        ]) && (
                          <Grid item className={classes.rightSiblingGridField} xs={12} sm={12} md={6} lg={6}>
                            <QCXFinalTextField
                              id="text-field-numero-rof"
                              key="text-field-numero-rof"
                              name="numeroRof"
                              label={t('com.muralis.qcx.numeroROF')}
                              disabled={isConsult}
                            />
                          </Grid>
                        )}
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          {...(!isSomeCoberturaCambial(values?.coberturaCambial, [
                            COBERTURA_CAMBIAL_SEM_COBERTURA,
                            COBERTURA_CAMBIAL_COM_COBERTURA_ACIMA_360_DIAS,
                          ])
                            ? {
                                md: 12,
                                lg: 12,
                              }
                            : {
                                className: classes.leftSiblingGridField,
                                md: 6,
                                lg: 6,
                              })}
                        >
                          <QCXFinalTextField
                            id="text-field-destaque-ncm"
                            key="text-field-destaque-ncm"
                            name="destaqueNcm"
                            label={t('com.muralis.qcx.NCM.destaqueNCM')}
                            maxLength={3}
                            disabled={isConsult}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <QCXSelectPortoAeroportoAutocomplete
                          id="select-field-local-condicao-venda"
                          key="select-field-local-condicao-venda"
                          name="portoAeroporto.id"
                          label={t('com.muralis.qcx.localCondicaoVenda')}
                          disabled={isConsult}
                          initialValues={values}
                        />
                      </Grid>

                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <QCXFinalNumericIntegerField
                          id="text-field-quebra-auxiliar"
                          key="text-field-quebra-auxiliar"
                          name="quebraAuxiliar"
                          label={t('com.muralis.qcx.mercadoria.quebraAdicional')}
                          disabled={isConsult}
                        />
                      </Grid>

                      <Grid item container alignContent="flex-start" xs={12} sm={12} md={12} lg={12}>
                        <Grid item xs={12}>
                          <QCXFormSubtitle
                            title={t('com.muralis.qcx.adicao.quebraAdicao')}
                            boxProps={{
                              pt: 1,
                              pb: 1,
                            }}
                          />
                        </Grid>
                        <Grid item className={classes.checkboxGridField} xs={12} sm={6} md={3} lg={3}>
                          <QCXFinalCheckboxField
                            id="checkbox-quebra-adicao-cfop-field"
                            key="checkbox-quebra-adicao-cfop-field"
                            name="quebraAdicaoCfop"
                            label={t('com.muralis.qcx.porCFOP')}
                            disabled={isConsult}
                          />
                        </Grid>
                        <Grid item className={classes.checkboxGridField} xs={12} sm={6} md={3} lg={3}>
                          <QCXFinalCheckboxField
                            id="checkbox-quebra-adicao-numero-fatura-field"
                            key="checkbox-quebra-adicao-numero-fatura-field"
                            name="quebraAdicaoNumeroFatura"
                            label={t('com.muralis.qcx.porNumeroFatura')}
                            disabled={isConsult}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                  <>
                    <Grid item container spacing={2}>
                      {/*
                      ========> ANTIGO
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <QCXFormTitle
                          title={t('com.muralis.qcx.imposto.II')}
                          boxProps={{
                            pt: 0,
                          }}
                        />
                      </Grid>
                      <QCXIICommonFormGroup modes={modes} rootFieldName="data" parentModel={parentModel} />
                      {!isSomeRegimeTributacaoIIBy(values, [
                        REGIME_TRIBUTACAO_IMUNIDADE_CODE,
                        REGIME_TRIBUTACAO_NAO_INCIDENCIA_CODE,
                      ]) &&
                        !isSomeTipoDeclaracaoBy([
                          TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
                          TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
                        ]) && (
                          <>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <QCXFormTitle title={t('com.muralis.qcx.imposto.IPI')} />
                            </Grid>
                            <QCXIPICommonFormGroup modes={modes} rootFieldName="data" />
                          </>
                        )}
                      {!isSomeTipoDeclaracaoBy([
                        TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
                        TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
                      ]) && (
                        <>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <QCXFormTitle title={t('com.muralis.qcx.legislacao.PISCOFINS')} />
                          </Grid>
                          <QCXPisCofinsCommonFormGroup modes={modes} />
                        </>
                      )}
                      {!isSomeTipoDeclaracaoBy([
                        TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
                        TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
                      ]) && (
                        <>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <QCXFormTitle title={t('com.muralis.qcx.ICMS.label')} />
                          </Grid>
                          <QCXIcmsCommonFormGroup modes={modes} />
                        </>
                      )}
*/}

                      <>
                        <Grid
                          item
                          style={{
                            paddingTop: '0px',
                          }}
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                        >
                          <QCXFormTitle
                            title={t('com.muralis.qcx.imposto.II')}
                            boxProps={{
                              pt: 0,
                            }}
                          />
                        </Grid>
                        <QCXIIFormGroup
                          intl={BRAZILIAN}
                          modes={modes}
                          submitSucceeded={submitSucceeded}
                          parentModel={parentModel}
                        />
                        {!isSomeRegimeTributacaoIIBy(values, [
                          REGIME_TRIBUTACAO_IMUNIDADE_CODE,
                          REGIME_TRIBUTACAO_NAO_INCIDENCIA_CODE,
                        ]) &&
                          !isSomeTipoDeclaracaoBy([
                            TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
                            TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
                          ]) && (
                            <>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <QCXFormTitle title={t('com.muralis.qcx.imposto.IPI')} />
                              </Grid>
                              <QCXIPIFormGroup
                                intl={BRAZILIAN}
                                modes={modes}
                                parentModel={parentModel}
                                submitSucceeded={submitSucceeded}
                              />
                            </>
                          )}
                        {!isSomeTipoDeclaracaoBy([
                          TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
                          TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
                        ]) && (
                          <>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <QCXFormTitle title={t('com.muralis.qcx.legislacao.PISCOFINS')} />
                            </Grid>
                            <QCXPisCofinsFormGroup
                              intl={BRAZILIAN}
                              modes={modes}
                              parentModel={parentModel}
                              submitSucceeded={submitSucceeded}
                            />
                          </>
                        )}
                        {!isSomeTipoDeclaracaoBy([
                          TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
                          TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
                        ]) && (
                          <>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <QCXFormTitle title={t('com.muralis.qcx.exportacao.antidumping')} />
                            </Grid>
                            <QCXAntidumpingFormGroup intl={BRAZILIAN} modes={modes} submitSucceeded={submitSucceeded} />
                          </>
                        )}
                        {!isSomeTipoDeclaracaoBy([
                          TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
                          TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
                        ]) &&
                          (parentModel?.followUp?.servico?.viaTransporte?.code === '01' ||
                            parentModel?.followUp?.servico?.viaTransporte?.code === '02' ||
                            parentModel?.followUp?.servico?.viaTransporte?.code === '03') && (
                            <>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <QCXFormTitle title={t('com.muralis.qcx.frete.AFRMM')} />
                              </Grid>
                              <QCXAFRMMFormGroup intl={BRAZILIAN} modes={modes} submitSucceeded={submitSucceeded} />
                            </>
                          )}
                        {!isSomeTipoDeclaracaoBy([
                          TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
                          TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
                        ]) && (
                          <>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <QCXFormTitle title={t('com.muralis.qcx.taxaSiscomex')} />
                            </Grid>
                            <QCXSisComexFormGroup intl={BRAZILIAN} modes={modes} submitSucceeded={submitSucceeded} />
                          </>
                        )}
                        <>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <QCXFormTitle title={t('com.muralis.qcx.multaLi.label')} />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <QCXFinalCheckboxField
                              name="multaLi.calculaMultaLi"
                              label={t('com.muralis.qcx.multaLi.calculaMultaLi')}
                              disabled={isConsult}
                            />
                          </Grid>
                          {values?.multaLi?.calculaMultaLi && (
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <QCXFinalCheckboxField
                                name="multaLi.multaPossuiReducao"
                                label={t('com.muralis.qcx.multaLi.comReducao')}
                                disabled={isConsult}
                              />
                            </Grid>
                          )}
                          {values?.multaLi?.calculaMultaLi && (
                            <Grid item xs={12} sm={6} md={6} lg={6} style={{ paddingTop: '2vh' }}>
                              <QCXFinalAlternativeCurrencyField
                                id="valor-devido-reais-tarifario-field-icms"
                                key="valor-devido-reais-tarifario-field-icms"
                                name="multaLi.valorDevidoReal"
                                label={t('com.muralis.qcx.valorDevidoReais')}
                                adorned
                                disabled
                              />
                            </Grid>
                          )}
                          {values?.multaLi?.calculaMultaLi && (
                            <Grid item xs={12} sm={6} md={6} lg={6} style={{ paddingTop: '2vh' }}>
                              <QCXFinalAlternativeCurrencyField
                                id="valor-recolher-reais-tarifario-field-icms"
                                key="valor-recolher-tarifario-field-icms"
                                name="multaLi.valorRecolherReal"
                                label={t('com.muralis.qcx.valorRecolherReais')}
                                adorned
                                disabled
                              />
                            </Grid>
                          )}
                        </>

                        {!isSomeTipoDeclaracaoBy([
                          TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
                          TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
                        ]) && (
                          <>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <QCXFormTitle title={t('com.muralis.qcx.ICMS.label')} />
                            </Grid>
                            <QCXIcmsFormGroup
                              intl={BRAZILIAN}
                              modes={modes}
                              parentModel={parentModel}
                              submitSucceeded={submitSucceeded}
                            />
                          </>
                        )}
                      </>
                    </Grid>
                  </>

                  <>
                    {/* TERCEIRA TAB */}

                    {!isSomeTipoDeclaracaoBy([TIPO_DECLARACAO_DUIMP]) && (
                      <>
                        <Grid item xs={12}>
                          <QCXFormSubtitle title={t('com.muralis.qcx.NVE.NVEatributos')} />
                        </Grid>
                        <h1> </h1>
                        <QCXFinalAtributoNveBondManagerManyAdicoes {...atributosNveProps} />
                      </>
                    )}
                  </>
                </QCXTabsManager>
              </Grid>
            </QCXFinalFormDataReplicator>
          </>
          <QCXConfirmDialog
            key="confirm-dialog-remove-adicao"
            id="confirm-dialog-remove-adicao"
            open={operationOfReplicate?.active}
            title={operationOfReplicate?.title}
            content={operationOfReplicate?.message}
            endContent={operationOfReplicate?.endMessage}
            onConfirm={operationOfReplicate.confirm}
            onClose={operationOfReplicate?.reset}
          />
        </>
      )}
    </QCXFormStepWizard>
  );
}
