import { UseMutateFunction } from "react-query";
import {
  QueriesTypes,
  usePostRequestFormData,
} from "../../../../common/hooks/queries";

export const useCreateFollowUpAssessoria = ({
  onSettled,
}: {
  onSettled: (payload: { data?: any; error?: any }) => void;
}) => {
  const { isLoading, isError, mutate } = usePostRequestFormData(
    QueriesTypes.FollowUps,
    {
      onSettledCallback: (payload?: { data?: any; error?: any }) => {
        onSettled(payload!);
      },
    }
  );

  return [mutate, isLoading, isError] as const;
};


