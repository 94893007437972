const BRAZILIAN = 'BRAZILIAN';
const AMERICAN = 'AMERICAN';
const DOT = 'DOT';

const MASK_NUMERIC_DECIMAL = 'MASK_NUMERIC_DECIMAL';

const MASK_NUMERIC_DECIMAL_BRAZILIAN = [MASK_NUMERIC_DECIMAL, BRAZILIAN];
const MASK_NUMERIC_DECIMAL_AMERICAN = [MASK_NUMERIC_DECIMAL, AMERICAN];
const MASK_NUMERIC_DECIMAL_DOT = [MASK_NUMERIC_DECIMAL, DOT];

const MASK_NUMERIC_INTEGER = ['MASK_NUMERIC_INTEGER'];
const MASK_DIGITS = ['MASK_DIGITS'];

export { MASK_NUMERIC_DECIMAL, BRAZILIAN, AMERICAN, DOT };

export {
  MASK_NUMERIC_INTEGER,
  MASK_DIGITS,
  MASK_NUMERIC_DECIMAL_BRAZILIAN,
  MASK_NUMERIC_DECIMAL_AMERICAN,
  MASK_NUMERIC_DECIMAL_DOT,
};
