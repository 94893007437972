import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { useHistory } from 'react-router';
import { Button, FormControl, Grid, TextField } from '@mui/material';
import FormHeaderMedium from '../../../common/components/forms/formHeaderMedium';
import SelectStyled from '../../../common/components/input/selectStyled';
import { SyntheticEvent, useState } from 'react';
import {
  selectUnidadesDeNegocioAssociadas,
  selectUnidadeSelecionada,
} from '../../../../features/usuario-logado/usuarioLogadoSlice';
import { useUnidadeNegocioGuard } from '../../../common/hooks/useUnidadeNegocioGuard';
import { KEYCLOAK_TOKEN_TIMEOUT } from '../../../../App';
import { setErrorFeedback, setSuccessFeedback } from '../../../../features/feedback/feedbackSlice';
import { generateSpreadsheetNfEmitidas } from './relatorioNfEmitidasPage.helpers';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, MenuItem } from '@material-ui/core';
import { GridItemModalProps } from '../../../common/components/cardsGrid/cardsModalGrid';
import { Field, Form } from 'react-final-form';
import QCXFinalDatePickerField from '../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import { useListClientes } from '../../../common/hooks/useListClientes';
import MultiSelectStyled from '../../../common/components/input/multiSelectStyled';
import QCXTextField from '../../../../shared-components/text-field/QCXTextField';

interface ControlledValue {
  id: string | number;
  value: string;
}
interface IUnidadesDeNegocioAssociadas {
  code: string;
  description: string;
  id: number;
  pessoa: {
    nome: string;
    nomeResumido: string;
  };
  sigla: string;
}
interface Datas {
  dataInicio: string;
  dataFim: string;
}

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      minWidth: '50vw',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    gap: '10px', // Ajuste o gap para controlar a distância entre os inputs
  },
  input: {
    width: '50%',
    marginRight: '1px', // Reduz a margem direita para aproximar os inputs
    borderRadius: '4px',
  },
  button: {
    borderRadius: '4px',
  },
});

const RelatorioNfEmitidasPage = ({ title, isOpen, closeModal }: GridItemModalProps) => {
  const classes = useStyles();
  useUnidadeNegocioGuard();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const { token } = keycloak;
  keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
  const [selectedClients, setSelectedClients] = useState<ControlledValue[]>([]);
  const [origem, setOrigem] = useState('');
  const [clientes] = useListClientes();
  const [status, setStatus] = useState('');


  const selectedUnit = useSelector(selectUnidadeSelecionada);
  const list: IUnidadesDeNegocioAssociadas[] = useSelector(selectUnidadesDeNegocioAssociadas);

  const [selectedBusiness, setSelectedBusiness] = useState<ControlledValue>();
  const [datas, setDatas] = useState<Datas>({
    dataInicio: '',
    dataFim: '',
  });

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.value);
};


  const validarDatas = (datas: Datas): boolean => {
    if (!datas.dataInicio || !datas.dataFim) {
      return false;
    }

    const dataInicio = new Date(datas.dataInicio);
    const dataFim = new Date(datas.dataFim);

    return dataInicio <= dataFim;
  };

  const handleClientsChange = (event: SyntheticEvent<Element, Event>, value: ControlledValue[] | null) => {
    setSelectedClients(value || []);
  };
  
  const handleOrigemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrigem(event.target.value);
  };

  const handleChangeDataInicio = (isoDate: string) => {
    const value = isoDate.split('T')[0];

    setDatas((prevDatas) => ({ ...prevDatas, dataInicio: value }));
  };

  const handleChangeDataFim = (isoDate: string) => {
    const value = isoDate.split('T')[0];

    setDatas((prevDatas) => ({ ...prevDatas, dataFim: value }));
  };

  const handleBusinessChange = (
    event: SyntheticEvent<Element, Event>,
    value: {
      id: string | number | undefined;
      value: string | undefined;
    } | null
  ) => {
    if (value == null) setSelectedBusiness(undefined);
    else
      setSelectedBusiness({
        id: value!.id as string,
        value: value!.value as string,
      });
  };

  const handleSubmit = async () => {
    keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);

    if (!validarDatas(datas)) {
      dispatch(
        setErrorFeedback({
          message: 'Preencha as datas de início e fim corretamente.',
        })
      );
      return;
    }

    if (token && selectedUnit) {
      try {
        const clientIds = selectedClients.map((client) => client.id.toString());
  
        const error: string = await generateSpreadsheetNfEmitidas(
          datas.dataInicio,
          datas.dataFim,
          token,
          selectedUnit,
          selectedBusiness ? selectedBusiness.id.toString() : '',
          clientIds,
          origem,
          status // Pass status
        );
        if (error != null) {
          dispatch(
            setErrorFeedback({
              message: error ? error : t('com.muralis.qcx.integracao.Planilhas.erros.erroAoGerarPlanilha'),
            })
          );
        } else {
          dispatch(
            setSuccessFeedback({
              message: t('com.muralis.qcx.integracao.Planilhas.sucesso.planilhaComSuceso'),
            })
          );
        }
      } catch (error) {
        dispatch(
          setErrorFeedback({
            message: t('translation.com.muralis.qcx.integracao.Planilhas.erros.erroInesperado'),
          })
        );
      }
    }

    keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
  };

  return (
    <Dialog className={classes.root} open={isOpen} onClose={closeModal}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Form onSubmit={() => {}}>
          {() => (
            <form>
              <Grid container spacing={2}>
                <FormHeaderMedium>Unidade de negócio</FormHeaderMedium>
  
                {/* Unidade de Negócio */}
                <Grid item xs={12} style={{ marginBottom: '15px' }}>
                  <FormControl fullWidth>
                    <SelectStyled
                      disablePortal={false}
                      controlledValue={selectedBusiness}
                      options={list.map(
                        (business) =>
                          ({
                            id: business.id,
                            value: business.pessoa.nomeResumido,
                          } as ControlledValue)
                      )}
                      onChangeAction={handleBusinessChange}
                      label={'Unidade de Negócio'}
                    ></SelectStyled>
                  </FormControl>
                </Grid>
  
                {/* Clientes */}
                <Grid item xs={12} style={{ marginBottom: '15px' }}>
                  <FormControl fullWidth>
                    <MultiSelectStyled
                      disablePortal={false}
                      options={clientes.map((client) => ({
                        id: client.id ?? '',
                        value: `(${client.pessoa!.cnpj}) ${client.pessoa!.nome}`,
                      }))}
                      onChangeAction={handleClientsChange}
                      label={'Clientes'}
                    />
                  </FormControl>
                </Grid>
{/*   
                <Grid item xs={12} style={{ marginBottom: '15px' }}>
                  <FormControl fullWidth>
                    <QCXTextField
                      style={{ width: '100%' }}
                      label="Origem"
                      key="select-origem-nf-emitidas"
                      id="select-origem-nf-emitidas"
                      select
                      value={origem}
                      onChange={handleOrigemChange}
                      {...({} as any)}
                    >
                      <MenuItem value="OPERACIONAL">OPERACIONAL</MenuItem>
                      <MenuItem value="ADMINISTRATIVA">ADMINISTRATIVA</MenuItem>
                    </QCXTextField>
                  </FormControl>
                </Grid> */}

                <Grid item xs={12} style={{ marginBottom: '15px' }}>
                  <FormControl fullWidth>
                    <QCXTextField
                      style={{ width: '100%' }}
                      label="Status"
                      key="select-status-nf-emitidas"
                      id="select-status-nf-emitidas"
                      select
                      value={status}
                      onChange={handleStatusChange}
                      {...({} as any)}
                    >
                      <MenuItem value="">Todos</MenuItem>
                      <MenuItem value="NFSE_EMITIDA">NFSE_EMITIDA</MenuItem>
                      <MenuItem value="NFSE_CANCELADA">NFSE_CANCELADA</MenuItem>
                    </QCXTextField>
                  </FormControl>
                </Grid>

  
                {/* Date Pickers */}
                <Grid item xs={6} style={{ marginBottom: '25px' }}>
                  <Field
                    name="startDate"
                    render={({ input }) => (
                      <QCXFinalDatePickerField
                        id="contas-pagar-vencimento-start-date"
                        name={input.name}
                        label={'Data de Início'}
                        required={true}
                        format="DD/MM/YYYY"
                        placeholder="DD/MM/YYYY"
                        onChange={(date: any) => {
                          handleChangeDataInicio(date.toISOString());
                          input.onChange(date);
                        }}
                        validate={undefined}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} style={{ marginBottom: '25px' }}>
                  <Field
                    name="endDate"
                    render={({ input }) => (
                      <QCXFinalDatePickerField
                        id="contas-pagar-vencimento-end-date"
                        name={input.name}
                        label={'Data final'}
                        required={true}
                        format="DD/MM/YYYY"
                        placeholder="DD/MM/YYYY"
                        onChange={(date: any) => {
                          handleChangeDataFim(date.toISOString());
                          input.onChange(date);
                        }}
                        validate={undefined}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
      </DialogContent>
      <DialogActions>
        <Box padding={2} display="flex" gridGap={4}>
          <Button variant="outlined" color="primary" onClick={closeModal}>
            Fechar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            fullWidth
            disabled={selectedBusiness == undefined || datas.dataFim == '' || datas.dataInicio == ''}
          >
            Gerar Relatório
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
export default RelatorioNfEmitidasPage;
