import { saveAs } from 'file-saver';

export interface GenerateDiResponse {
  statusCode: number;
  message: string;
}

export async function downloadFile(token: string, selectedUnidade: string, url: string, filename: string): Promise<GenerateDiResponse> {

  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.append('Authorization', `Bearer ${token}`);
  requestHeaders.append('quickcomexTenant', selectedUnidade);

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: requestHeaders,
    });

    if (!response.ok) {
      try {
        const data = await response.json();
        const message: string = data.message;
        return { statusCode: 500, message: `Erro no download: ${message}` };
      } catch (error : any) {
        const message : string = error?.response ? error?.response.data : "Erro ao gerar Xml da Di";
        return { statusCode: 500, message};
      }
    }

    const blob = await response.blob();
    saveAs(blob, filename + ".xml");
    return { statusCode: 200, message: `Download realizado com sucesso!` };
  } catch (error) {
    return { statusCode: 500, message: "Erro inesperado ao realizar o download." };
  }
}
