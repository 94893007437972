import axios from 'axios';

const baseUrl =
  process.env.REACT_APP_QCX_API_BASE_URL! +
  '/' +
  process.env.REACT_APP_RELATORIOS_API_DESPESAS_PAGAS!;

export const generateDespesasPagasReport = async (
  token: string,
  unidadeId: string,
  tenantIds: string[],
  clientIds: string[],
  fornecedorId: string,
  despesaId: string, // Add despesaId parameter
  startDateISO: string,
  endDateISO: string,
  origem: string,
  bancoId: number | null
): Promise<string | null> => {
  const params = new URLSearchParams();
  params.set('tenantId', tenantIds.join(','));

  // Set date range
  const startDate = startDateISO.split('T')[0];
  const endDate = endDateISO.split('T')[0];
  params.set('startDate', startDate);
  params.set('endDate', endDate);

  if (clientIds.length > 0) {
    params.set('clientesId', clientIds.join(',')); // Include clients in API call
  }

  if (fornecedorId) {
    params.set('fornecedorId', fornecedorId);
  }

  if (despesaId) {
    params.set('despesaId', despesaId); // Include despesaId in API call
  }

  if (origem) {
    params.set('origem', origem);
  }

  if (bancoId) {
    params.set('bancoId', bancoId.toString());
  }

  try {
    const response = await axios.get(`${baseUrl}?${params.toString()}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        quickcomexTenant: unidadeId,
      },
      responseType: 'blob',
    });

    if (response.status === 200) {
      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'relatorioDespesasPagas.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      const errorText = await response.data.text();
      return errorText;
    }
  } catch (error) {
    let message = 'Erro ao gerar o relatório.';

    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data) {
        message = await error.response.data.text();
      }
    }

    return message;
  }

  return null;
};
