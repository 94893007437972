import _ from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import QCXFinalFormDialog from '../../shared-components/dialog/QCXFinalFormDialog';

import { CLOSED_STATUS, isOpenStatus } from '../../utils/hooks/form/dialog/formDialogUtils';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXSelectContaBancariaAutocompleteField from '../../shared-components/select-conta-bancaria/QCXSelectContaBancariaAutocompleteField';

export default function QCXPartialValuesDialogForm({
  handleOnSubmit,
  handleFormStatus,
  formDialogStatus,
  isSingleItemSelected,
  initialValues,
}) {
  const { t } = useTranslation();

  useEffect(() => {}, [initialValues]);

  const isFormDialogOpen = useMemo(() => isOpenStatus(formDialogStatus), [formDialogStatus]);

  const handleCloseFormDialogNew = useCallback(() => {
    handleFormStatus(CLOSED_STATUS);
    _.debounce(() => {}, 200)();
  }, [handleFormStatus]);

  return (
    <QCXFinalFormDialog
      key="form-dialog-relatorio-conta-pagar-receber"
      id="form-dialog-relatorio-conta-pagar-receber"
      title="Receber Valor Parcial"
      dialogActionsProps={{
        button: {
          submit: {
            description: 'Receber',
            size: 'small',
            color: 'secondary',
          },
          cancel: {
            description: t('com.muralis.qcx.acoes.cancelar'),
            size: 'small',
          },
        },
      }}
      open={isFormDialogOpen}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      onClose={handleCloseFormDialogNew}
    >
      {() => (
        <>
          <Grid>
            <QCXFinalAlternativeCurrencyField 
            name="valorParcial" 
            label="Valor Recebido" 
            required               
            disabled={!isSingleItemSelected}/>
          </Grid>
          <Grid style={{ paddingTop: '1rem' }}>
            <QCXFinalDatePickerField name="dataPagamento" label="Data Pagamento" required />
          </Grid>
          <Grid style={{ paddingTop: '1rem' }} item xs={12} sm={12} md={12} lg={12}>
            <QCXSelectContaBancariaAutocompleteField
              id="select-conta-bancaria"
              key="select-conta-bancaria"
              name="contaBancariaId"
              label="Conta Bancária"
              filtraControlaSaldo
              required
            />
          </Grid>
        </>
      )}
    </QCXFinalFormDialog>
  );
}
