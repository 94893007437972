import AssessmentIcon from '@mui/icons-material/Assessment';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CardsGrid, { CardsGridItem } from '../../common/components/cardsGrid/cardsGrid';
import BasicLayout from '../../common/layouts/basicLayout';

const gridItems: CardsGridItem[] = [
  {
    title: 'Halliburton',
    icon: InsertDriveFileIcon,
    path: '/relatorios/halliburton',
    requiredGroups: ['8246575', '154792'],
    requiredRoles: ['relatorio-halliburton-visualizar'],
  },
  {
    title: 'Draft DANFE Excel',
    icon: InsertDriveFileIcon,
    path: '/relatorios/danfe',
    requiredRoles: ['relatorio-draft-danfe-excel-visualizar'],
  },
  {
    title: 'Draft DANFE Pfizer Excel',
    icon: InsertDriveFileIcon,
    path: '/relatorios/danfeFaxe',
    requiredRoles: ['relatorio-draft-danfe-faxe-visualizar'],
  },
  {
    title: 'Planilha LA',
    icon: InsertDriveFileIcon,
    path: '/relatorios/la',
    requiredRoles: ['relatorio-planilha-la-visualizar'],
  },
  {
    title: 'Associated',
    icon: InsertDriveFileIcon,
    path: '/relatorios/associated',
    requiredGroups: ['8246575', '154792'],
    requiredRoles: ['relatorio-associated-visualizar'],
  },
  {
    title: 'Planilha Transportadoras',
    icon: InsertDriveFileIcon,
    path: '/relatorios/transportadoras',
    requiredRoles: ['relatorio-planilha-transportadoras-visualizar'],
  },
  {
    title: 'Red Bull',
    icon: InsertDriveFileIcon,
    path: '/relatorios/redbull',
    requiredRoles: ['red-bull-visualizar'],
  },
  {
    title: 'Acompanhamento Gerencial',
    icon: InsertDriveFileIcon,
    path: '/relatorios/gerencial',
    requiredRoles: ['relatorio-gerencial-visualizar']
  },
  {
    title: 'Processos em Andamento',
    icon: InsertDriveFileIcon,
    path: '/relatorios/processos',
    requiredRoles: ['relatorio-processos-visualizar']
  },
  {
    title: 'Dashboard',
    icon: InsertDriveFileIcon,
    path: '/relatorios/dashboard',
    requiredRoles: ['relatorio-dashboard-visualizar'],
  },
  {
    title: 'Mapa de Processos',
    icon: InsertDriveFileIcon,
    path: '/relatorios/mapa-processos',
  },
  {
    title: 'Processos Desembaraçados',
    icon: InsertDriveFileIcon,
    path: '/relatorios/processos-desembaracados',
  },
  {
    title: 'Dashboard SGT',
    icon: InsertDriveFileIcon,
    path: '/relatorios/dashboard-sgt',
  },
  {
    title: 'Conta azul',
    icon: InsertDriveFileIcon,
    path: '/relatorios/conta-azul',
  }
];

const RelatoriosPage = () => {
  return (
    <BasicLayout title={'Relatórios'} icon={<AssessmentIcon color={'secondary'} />}>
      <CardsGrid items={gridItems}></CardsGrid>
    </BasicLayout>
  );
};

export default RelatoriosPage;
