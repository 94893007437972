import { createAsyncThunk } from '@reduxjs/toolkit';
import { isFunction } from 'lodash';
import { MediaType } from '../../utils/api/api-utils';
import { conciliacaoBancariaAPI } from './conciliacaoBancariaAPI';

const fetchConciliacaoPendenteAllAsync = createAsyncThunk(
  'conciliacaoBancaria/fetchConciliacaoPendenteAll',
  async () => {
    const { data } = await conciliacaoBancariaAPI.fetchConciliacaoPendenteAll();
    return { data };
  }
);

const fetchAllAsync = createAsyncThunk(
  'conciliacaoBancaria/listagem',
  async () => {
    const { data } = await conciliacaoBancariaAPI.fetchAll();
    return { data };
  }
);

const generateReportByDateAsync = createAsyncThunk(
  'conciliacaoBancaria/generateReportByDate',
  async (payload) => {
    try {
      const response = await conciliacaoBancariaAPI.generateReportByDate([
        {
          name: 'datainicial',
          value: payload?.dataInicial,
        },
        {
          name: 'datafinal',
          value: payload?.dataFinal,
        },
        {
          name: 'tenantId',
          value: payload?.selectedUnit,
        }
      ]);

      if (response?.status === 204) {
        const onNoContent = payload?.handlers?.onNoContent;
        if (isFunction(onNoContent)) {
          onNoContent();
          return;
        }
      }

      const xmlData = new Blob([response?.data], { type: MediaType.APPLICATION_PDF });

      const url = window.URL.createObjectURL(xmlData);
      window.open(url);
    } catch (e) {
      const onError = payload?.handlers?.onError;

      if (isFunction(onError)) {
        onError();
      }
    }
  }
);

const conciliacaoBancariaThunks = {
  fetchConciliacaoPendenteAllAsync,
  generateReportByDateAsync,
  fetchAllAsync,
};

export {
  conciliacaoBancariaThunks,
  generateReportByDateAsync,
  fetchConciliacaoPendenteAllAsync,
  fetchAllAsync,
};
