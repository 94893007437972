import {
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import {
  MoreHoriz,
  Search,
  FileCopy,
  Block,
  Timeline,
} from "@material-ui/icons";
import { t } from "i18next";
import { bindTrigger, bindMenu } from "material-ui-popup-state";
import React from "react";
import QCXPopupState from "../../../../components/popup-state/QCXPopupState";
import QCXListItemIcon from "../../../../shared-components/list-item-icon/QCXListItemIcon";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setErrorFeedback, setSuccessFeedback } from "../../../../features/feedback/feedbackSlice";
import { useKeycloak } from "@react-keycloak/web";
import { selectUnidadeSelecionada } from "../../../../features/usuario-logado/usuarioLogadoSlice";
import { KEYCLOAK_TOKEN_TIMEOUT } from "../../../../App";

type Props = {
  id: string;
};

const baseUrl = process.env.REACT_APP_QCX_API_BASE_URL!; // Ensure this is set in your .env file

const ActionsButtonCell = ({ id }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  
  // Retrieve token and selected business unit
  const { keycloak } = useKeycloak();
  keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
  const selectedUnit = useSelector(selectUnidadeSelecionada);

  const handleCancel = async () => {
    try {
      const token = keycloak.token;
      if (!token || !selectedUnit) {
        throw new Error("Token ou unidade de negócio não encontrado.");
      }

      const response = await fetch(`${baseUrl}/followup/inactivate/${id}/motivo`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          "quickcomexTenant": selectedUnit.toString()
        },
        body: JSON.stringify({
          motivoCancelamento: "Cancelado na pagina de assessoria followups",
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || "Erro ao cancelar Follow-up");
      }
      
      dispatch(setSuccessFeedback({ message: "Follow-up cancelado com sucesso!" }));
      
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        dispatch(setErrorFeedback({ message: error.message }));
      } else {
        dispatch(setErrorFeedback({ message: "Um erro desconhecido ocorreu" }));
      }
    }
  };

  return (
    <>
      <Tooltip title={"Visualizar Timeline"} arrow>
        <IconButton
          color="secondary"
          key={`btn-timeline-view-${id}`}
          name={`btn-timeline-view-${id}`}
          onClick={() => {
            history.push(`/assessoria/followup/${id}/timeline`);
          }}
        >
          <Timeline />
        </IconButton>
      </Tooltip>
      <QCXPopupState popupId="popup-menu-follow-up">
        {(popupState: any) => (
          <>
            <Tooltip title={"Mais opções"} arrow>
              <IconButton
                color="secondary"
                key={`btn-more-options-${id}`}
                name={`btn-more-options-${id}`}
                {...bindTrigger(popupState)}
              >
                <MoreHoriz />
              </IconButton>
            </Tooltip>
            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={() => history.push(`/assessoria/followup/${id}/detalhes`)}>
                <QCXListItemIcon>
                  <Search fontSize="small" />
                </QCXListItemIcon>
                <Typography variant="inherit" style={{ fontSize: 12 }}>
                  {t("com.muralis.qcx.acoes.consultar").toUpperCase()}
                </Typography>
              </MenuItem>

              <MenuItem onClick={() => {}} disabled>
                <QCXListItemIcon>
                  <FileCopy fontSize="small" color="disabled" />
                </QCXListItemIcon>
                <Typography variant="inherit" style={{ fontSize: 12 }}>
                  {t("com.muralis.qcx.acoes.duplicar").toUpperCase()}
                </Typography>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  popupState.close();
                  handleCancel();
                }}
              >
                <QCXListItemIcon>
                  <Block fontSize="small" color={"error"} />
                </QCXListItemIcon>
                <Typography variant="inherit" style={{ fontSize: 12 }}>
                  {t("com.muralis.qcx.acoes.cancelar").toUpperCase()}
                </Typography>
              </MenuItem>
            </Menu>
          </>
        )}
      </QCXPopupState>
    </>
  );
};

export default ActionsButtonCell;
